import { DeviceListItem } from "models/devices";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ButtonItem, useGridExportEvents } from "react-tools";

import { Delete } from "@material-ui/icons";
import Add from "@material-ui/icons/Add";
import CloudDownload from "@material-ui/icons/CloudDownload";
import Refresh from "@material-ui/icons/Refresh";

export const useDeviceListBladeButtons = (
  fetchDevices: () => void,
  createDevice: () => void,
  confirmDeleteDevices: () => void,
  selected: DeviceListItem[],
  loading: boolean,
  gridApi: any
) => {
  const exportFunctions = useGridExportEvents();
  const [t] = useTranslation();

  const buttons = useMemo<ButtonItem[]>(
    () => [
      {
        tooltip: "devices.refresh",
        icon: () => <Refresh />,
        onClick: fetchDevices,
        disabled: loading,
      },
      {
        tooltip: "devices.create",
        icon: () => <Add />,
        onClick: createDevice,
      },
      {
        tooltip: "devices.export",
        icon: () => <CloudDownload />,
        onClick: () => {
          if (exportFunctions && exportFunctions.exportCsv) {
            exportFunctions.exportCsv(t("devices.exportFileName").toString());
          }
        },
      },
      {
        tooltip: "devices.delete",
        icon: () => <Delete />,
        disabled: selected ? selected.length === 0 : true,
        onClick: confirmDeleteDevices,
      },
    ],
    [selected, loading, gridApi]
  );

  return buttons;
};

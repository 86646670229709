import { pushNotification, BladeActions } from "react-tools";
import * as actions from "./actions";
import { WorkgroupService } from "./service";
import { WorkgroupTreeActionNames, WorkgroupDetailsMode } from "./types";
import {
  Workgroup,
  WorkgroupDetailsModel,
  TemplateLibrary,
  ThunkTypeAction,
  ThunkTypeDispatch,
} from "models";
import { TreeActions, SelectionMode } from "react-tools";
import { WORKGROUP_TREE_NAME } from "../../constants";
import { selectWorkgroupDetails } from "./selectors";
import { Dispatch } from "redux";

import i18n from "localization/i18n";

const service = new WorkgroupService();

const sortWorkgroups = (allWorkgroups: Workgroup[]): Workgroup[] => {
  const normalized: Array<{ parentId: number; workgroups: Workgroup[] }> = [];

  allWorkgroups.forEach((workgroup: Workgroup) => {
    const parent = normalized.find((e) => e.parentId === workgroup.parentId);
    if (parent) {
      parent.workgroups.push(workgroup);
    } else {
      normalized.push({
        parentId: workgroup.parentId,
        workgroups: [workgroup],
      });
    }
  });

  const result: Workgroup[] = [];

  normalized.forEach(({ workgroups }) => {
    const sorted = workgroups.sort((a, b) =>
      a.name < b.name ? -1 : a.name > b.name ? 1 : 0
    );
    result.push(...sorted);
  });

  return result;
};

export const fetchWorkgroupsAsync = (): ThunkTypeAction<void> => {
  return async (dispatch: ThunkTypeDispatch) => {
    dispatch(actions.fetchWorkgroupsStart());

    try {
      const workgroups: Workgroup[] = await service.getWorkgroups();

      dispatch(
        TreeActions.initTree(
          WORKGROUP_TREE_NAME,
          sortWorkgroups(workgroups),
          SelectionMode.SingleSelection,
          2
        )
      );

      dispatch(actions.fetchWorkgroupsSuccess(workgroups));
      dispatch(fetchWorkgroupsSuccess());
    } catch (err) {
      dispatch(pushNotification(`Could not fetch workgroups: ${err}`, "error"));
    }
  };
};

export const fetchWorkgroupsSuccess = (): ThunkTypeAction<void> => {
  return async (dispatch: ThunkTypeDispatch, getState: () => any) => {
    const workgroup: WorkgroupDetailsModel | null = selectWorkgroupDetails(
      getState()
    );

    if (workgroup && workgroup.id) {
      // after we save a workgroup and reinitialize the workgroup tree we open select the workgroup
      // in the tree, expand it accordingly and open the workgroup details blade.
      dispatch(TreeActions.selectModel(WORKGROUP_TREE_NAME, workgroup.id));
    }
  };
};

export const fetchWorkgroupAsync = (workgroupId: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.fetchWorkgroupDetailsStart(workgroupId));

      const workgroup: WorkgroupDetailsModel = await service.getWorkgroup(
        workgroupId
      );
      dispatch(actions.fetchWorkgroupDetailsSuccess(workgroup));
    } catch (err) {
      dispatch(pushNotification(`Could not fetch workgroup: ${err}`, "error"));
    }
  };
};

export const saveWorkgroupAsync = (
  workgroup: WorkgroupDetailsModel,
  mode: WorkgroupDetailsMode
) => {
  return async (dispatch: ThunkTypeDispatch) => {
    dispatch(actions.saveWorkgroupStart(workgroup, mode));

    var successMessage =
      mode === WorkgroupDetailsMode.NEW
        ? "Workgroup created."
        : "Workgroup saved.";
    var errorMessage =
      mode === WorkgroupDetailsMode.NEW
        ? "Could not create workgroup."
        : "Could not save workgroup.";

    try {
      var response = await service.saveWorkgroup(workgroup, mode);
      workgroup.id = response.id;
      dispatch(pushNotification(successMessage, "success"));
      dispatch(actions.saveWorkgroupSuccess(workgroup));
      dispatch(fetchWorkgroupsAsync());
    } catch (error) {
      dispatch(actions.saveWorkgroupFail());
      dispatch(pushNotification(errorMessage, "error"));
    }
  };
};

export const fetchTemplatesAsync = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.fetchTemplatesStart());

      const templates: TemplateLibrary[] = await service.getTemplates();
      dispatch(actions.fetchTemplatesSuccess(templates));
    } catch (err) {
      dispatch(actions.fetchTemplatesFail(err));
    }
  };
};

export const setWorkgroupAsFavorite = (workgroupId: number) => {
  return (dispatch: Dispatch) => {
    dispatch(
      TreeActions.setActionLoading(
        WORKGROUP_TREE_NAME,
        workgroupId,
        WorkgroupTreeActionNames.ToggleFavorite
      )
    );
    dispatch(actions.setWorkgroupAsFavoriteSuccess(workgroupId));
    dispatch(
      TreeActions.clearActionLoading(
        WORKGROUP_TREE_NAME,
        workgroupId,
        WorkgroupTreeActionNames.ToggleFavorite
      )
    );
  };
};

export const removeWorkgroupAsFavorite = (workgroupId: number) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.removeWorkgroupAsFavoriteSuccess(workgroupId));
  };
};

export const deleteWorkgroupAsync = (workgroupId: number, bladeId: string) => {
  return async (dispatch: ThunkTypeDispatch) => {
    dispatch(actions.deleteWorkgroup(workgroupId));

    try {
      await service.deleteWorkgroup(workgroupId);
      dispatch(actions.deleteWorkgroupSuccess(workgroupId));
      dispatch(
        pushNotification(i18n.t("workgroupDetails.deleteSuccess"), "success")
      );
      dispatch(BladeActions.forceCloseChildrenBlades(bladeId));
      dispatch(BladeActions.closeBlade());
      dispatch(fetchWorkgroupsAsync());
    } catch (err) {
      dispatch(
        pushNotification(i18n.t("workgroupDetails.deleteFail"), "error")
      );
      dispatch(actions.deleteWorkgroupFail());
    }
  };
};

import { ColumnSettings } from 'react-tools';
import {
    InheritedColumnFormatter, TimestampColumnFormatter, ValueColumnFormatter
} from './column-formatters';
import { valueFilterSettings } from 'device-settings/common/valueFilterSettings';

export const deviceSettingsColumnSettings: Array<ColumnSettings> = [
  {
    name: "value",
    width: 80,
    formatter: ValueColumnFormatter,
    filter: valueFilterSettings.filter,
    customFloatingFilterParams: valueFilterSettings.customFloatingFilterParams
  },
  {
    name: "isInherited",
    width: 80,
    formatter: InheritedColumnFormatter,
    filter: 'agSetColumnFilter'
  },
  {
    name: 'riskLevelLabel',
    width: 80,
    filter: 'agSetColumnFilter'
  },
  {
    name: "timestamp",
    width: 120,
    formatter: TimestampColumnFormatter,
    suppressHeaderFilter: true,
    filter: false
  },
  {
    name: 'origin',
    width: 80,
    filter: 'agTextColumnFilter'
  },
  {
    name: 'username',
    width: 80,
    filter: 'agTextColumnFilter'
  }
];

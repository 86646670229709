import i18n from "localization/i18n";
import { ChannelListItem, ChannelType } from "models";
import React, { useCallback } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import {
  BladeActions,
  BladeConfig,
  BladeProps,
  DataGridContextProvider,
  IdName,
  withRegisterBlade,
} from "react-tools";

import { actions, selectors, thunks } from "../duck";
import { Channels } from "./channels";

export const ChannelsBladeName = "CHANNELS";

interface WorkgroupChannelsContainerProps extends BladeProps {
  workgroup: IdName;
}

const bladeConfig: BladeConfig<WorkgroupChannelsContainerProps> = {
  bladeType: ChannelsBladeName,
  size: { defaultWidth: 800, minWidth: 600 },
  title: (props: any) => i18n.t("channels.title"),
  allowMultipleInstances: false,
};

const ContainerComponent = (
  props: WorkgroupChannelsContainerProps & BladeProps
) => {
  const { workgroup, bladeId } = props;

  const dispatch = useDispatch();

  const items = useSelector(selectors.selectChannels);
  const fetchingChannels = useSelector(selectors.selectIsFetchingChannels);
  const preselected = useSelector(selectors.selectPreselected);

  const fetchChannels = useCallback(
    () => dispatch(thunks.fetchChannelsAsync(workgroup.id)),
    [workgroup.id]
  );

  const createChannel = useCallback(
    (channelType: ChannelType) =>
      batch(() =>
        dispatch(
          BladeActions.closeChildrenBlades(bladeId, [
            actions.clearChannelDetails(),
            actions.openChannelFormBlade(bladeId, {
              workgroup,
              channelName: "",
              channelId: 0,
              channelType,
            }),
          ])
        )
      ),
    [workgroup]
  );

  const editChannel = useCallback(
    (channel: ChannelListItem) =>
      batch(() => {
        dispatch(actions.setPreselected([channel]));
        dispatch(
          BladeActions.closeChildrenBlades(
            bladeId,
            actions.openChannelFormBlade(bladeId, {
              workgroup,
              channelName: channel.name,
              channelId: channel.id,
              channelType: channel.channelType,
            })
          )
        );
      }),
    [workgroup]
  );

  const deleteChannel = useCallback(
    (channel: ChannelListItem) =>
      batch(() => {
        dispatch(BladeActions.forceCloseChildrenBlades(props.bladeId));
        dispatch(thunks.deleteChannelAsync(channel));
      }),
    [props.bladeId]
  );

  const onClose = useCallback(() => clearPreselected(), []);
  const clearPreselected = useCallback(
    () => dispatch(actions.setPreselected([])),
    []
  );

  return (
    <DataGridContextProvider>
      <Channels
        bladeId={bladeId}
        onClose={onClose}
        preselected={preselected}
        clearPreselected={clearPreselected}
        workgroup={workgroup}
        items={items}
        fetchingChannels={fetchingChannels}
        fetchChannels={fetchChannels}
        createChannel={createChannel}
        deleteChannel={deleteChannel}
        editChannel={editChannel}
      />
    </DataGridContextProvider>
  );
};

export const WorkgroupChannelsContainer =
  withRegisterBlade(bladeConfig)(ContainerComponent);

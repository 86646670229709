export const resources = {
  en: {
    translation: {
      cancel: "Cancel",
      create: "Create",
      warning: "Warning",
      "warning!": "Warning!",
      proceed: "Proceed",
      save: "Save",
      help: "Help",
      close: "Close",
      confirm: "Confirm",
      next: "Next",
      yes: "Yes",
      no: "No",
      search: "Search",
      "No Data found": " No Data found",
      "blade.openHelpDialogButtonTooltip":
        "Show more information about blade content",
      "blade.closeBladeButtonTooltip": "Close Blade",
      "blade.closeDialog.title": "Close blade",
      "blade.closeDialog.message":
        "Are you sure you want to close these blades?",
      "blade.closeDialog.message.secondLine": "Some of them have unsaved data:",
      "blade.close.all": "Close all",
      "blade.dirty.warning": "This blade has unsaved changes",

      "validation.required": "Field is required",

      "workgroupLocations.noResults": "No Locations Found",
      "workgroupLocations.name": "Name",
      "workgroupLocations.address": "Address",
      "workgroupLocations.phoneNumber": "Phone",
      "workgroupLocations.zipCode": "Zip Code",
      "workgroupLocations.city": "City",
      "workgroupLocations.state": "State",
      "workgroupLocations.country": "Country",
      "workgroupLocations.customProperties": "Custom Properties",
      "workgroupLocations.oracleNumber": "Oracle No.",
      "workgroupLocations.id": "Id",
      "workgroupLocations.timezone": "Timezone",
      "workgroupLocations.workgroupId": "Workgroup Id",
      "workgroupLocations.workgroupName": "Workgroup Name",
      "workgroupLocations.recursive": "Include sub-workgroups",
      "workgroupLocations.selectedCount": "Selected",
      "workgroupLocations.create": "Create Location",
      "workgroupLocations.edit": "Edit Location",
      "workgroupLocations.delete": "Delete Location(s)",
      "workgroupLocations.listTitle": "Locations",
      "workgroupLocations.clientSiteId": "Client Site Id",
      "workgroupDetails.uploadButtonTooltip": "Upload new logo",
      "workgroupLocations.quickSearch": "Quick Search",
      "workgroupLocations.refresh": "Reload Locations",
      "workgroupLocations.export": "Export Locations",
      "workgroupLocations.exportFileName": "Locations",

      "locations.deleteTitle": "Delete Location(s)",
      "locations.deleteSingle":
        "Are you sure you want to delete the <b>{{name}} location including its devices?</b>",
      "locations.deleteMultiple":
        "Are you sure you want to delete <b>{{count}} locations including their devices?</b>",
      "locations.deleteDialogExtraText":
        "<b>{{deviceCount}} device(s) will be deleted.</b>",
      "locations.warning": "This operation cannot be reverted!",
      "locations.deleteWarning":
        "WARNING: This will also delete ALL the location's devices and streams!",
      "locations.deleteDeviceCount": "Number of devices",
      "locations.deleteCountCheckHelperText":
        "Enter the number of devices that will be deleted",
      "locations.checkboxText":
        "I acknowledge the impact of deleting a location",
      "locations.deletedSingle": "Location was deleted successfully",
      "locations.deletedMultiple": "Locations were deleted successfully",

      "workgroupDetails.new.title": "New Workgroup",
      "workgroupDetails.nav.locations": "Locations",
      "workgroupDetails.nav.devices": "Devices",
      "workgroupDetails.nav.deviceSettings": "Device Settings",
      "workgroupDetails.nav.streams": "Streams",
      "workgroupDetails.nav.channels": "Channels/Playlists",
      "workgroupDetails.footer.create": "Create",
      "workgroupDetails.footer.save": "Save",
      "workgroupDetails.footer.cancel": "Cancel",
      "workgroupDetails.fields.name": "Workgroup Name",
      "workgroupDetails.fields.id": "Workgroup Id",
      "workgroupDetails.isCompany": "Company Details",
      "workgroupDetails.tab.company": "Company",
      "workgroupDetails.tab.address": "Address",
      "workgroupDetails.tab.contact": "Contact",
      "workgroupDetails.fields.companyName": "Company Name",
      "workgroupDetails.fields.companyNumber": "Company Number",
      "workgroupDetails.fields.companyCountry": "Select Country",
      "workgroupDetails.fields.companyAddress": "Street Address",
      "workgroupDetails.fields.companyCity": "City",
      "workgroupDetails.fields.companyState": "State",
      "workgroupDetails.fields.companyZip": "Zip Code",
      "workgroupDetails.fields.companyFirstName": "First Name",
      "workgroupDetails.fields.companyLastName": "Last Name",
      "workgroupDetails.fields.companyPhone": "Phone Number",
      "workgroupDetails.fields.companyEmail": "Email Address",
      "workgroupDetails.logo.label": "Workgroup Logo",
      "workgroupDetails.logo.caption1": "Only .jpeg and .png formats.",
      "workgroupDetails.logo.caption2": "Must be less than xx size.",
      "workgroupDetails.templates.label": "Template Libraries:",
      "workgroupDetails.templates.empty": "No templates",
      "workgroupDetails.templates.select": "Select",
      "workgroupDetails.deleteSectionMessage":
        "<b>Warning:</b> Deleting the workgroup will also delete all the sub-workgroups, locations and devices that it contains. This action is irreversible!",
      "workgroupDetails.workgroupIsNonDeletable":
        "<b>Warning: </b>Cannot delete workgroup because either the workgroup or one or more of its children are marked as <b>not deletable.</b>",
      "workgroupDetails.deleteWorkgroupButtonText": "Delete workgroup",
      "workgroupDetails.deleteSuccess": "Workgroup successfully deleted",
      "workgroupDetails.deleteFail": "Error deleting workgroup",
      "templateLibraries.id": "Id",
      "templateLibraries.name": "Name",
      "templateLibraries.listTitle": "Template Libraries",
      "workgroupDetails.deleteMessage":
        'Are you sure you want to delete the <b>"{{workgroupName}}" workgroup? <br/> This operation cannot be reverted!</b>',
      "workgroupDetails.deleteWarning":
        "WARNING: this will also delete all the sub-workgroups, locations and devices belonging to the workgroup!",

      "workgroupDetails.deleteDialogTitle": "Delete workgroup",
      "workgroupDetails.checkboxText":
        "I acknowledge the impact of deleting the workgroup",

      "workgroupDetails.deleteCountMessage":
        "Sub-workgroups to be deleted: {{workgroupCount}} <br/>Locations to be deleted: {{locationCount}} <br/>Devices to be deleted: {{deviceCount}}",
      "locationForm.createTitle": "New Location",
      "locationForm.editTitle": "Edit location",
      "locationForm.locationInformation": "Location Information",
      "locationForm.locationAddress": "Location Address",
      "locationForm.concurrency":
        "The location you are editing has been changed and cannot be saved.",

      "devices.create": "Create Device",
      "devices.edit": "Edit Device",
      "devices.delete": "Delete Device(s)",
      "devices.deleteConfirm.content":
        "Are you sure you want to delete {{count}} device? Please type in the number of devices to confirm you want to proceed:",
      "devices.deleteConfirm.content_plural":
        "Are you sure you want to delete {{count}} devices? Please type in the number of devices to confirm you want to proceed:",
      "devices.deleteConfirm.countCheck": "Number of devices",
      "devices.refresh": "Reload Devices",
      "devices.export": "Export Devices",
      "devices.exportFileName": "Devices",
      "devices.selectedCount": "Selected",
      "devices.recursive": "Include sub-workgroups",
      "devices.noResults": "No Devices Found",
      "devices.title": "Devices",
      "devices.id": "Id",
      "devices.type": "Type",
      "devices.name": "Name",
      "devices.serial": "Serial Number",
      "devices.salesOrderNo": "Sales Order Number",
      "devices.playerType.windows": "Windows",
      "devices.playerType.android": "Android",
      "devices.playerType.brightsign": "Brightsign",
      "devices.playerType.ssp": "Samsung SSP",
      "devices.playerType.moki": "Moki",
      "devices.locationName": "Location",
      "devices.workgroupName": "Workgroup",

      "deviceForm.createTitle": "New Device",
      "deviceForm.deviceProperties": "Device Properties",
      "deviceForm.id": "Id",
      "deviceForm.name": "Device Name",
      "deviceForm.deviceTypeId": "Device Type",
      "deviceForm.salesOrderNumber": "Sales Order Number",
      "deviceForm.createStream": "Auto create stream",
      "deviceForm.location": "Location",
      "deviceForm.concurrency":
        "The device you are editing has been changed and cannot be saved",
      "deviceForm.serialConflict": "The serial is already in use",
      "deviceForm.playersFolder": "Could not create folder for player",
      "deviceForm.sqlError": "Database error occured while saving player",
      "deviceForm.timeoutError":
        "Communication with Settings Service Timed Out",
      "deviceForm.error": "Error creating device",
      "deviceForm.deleteDeviceSuccessToast": "Device was deleted successfully",
      "deviceForm.deleteDevicesSuccessToast":
        "Devices were deleted successfully",
      "deviceForm.saveDeviceSuccessToast": "Device created successfully",

      "deviceForm.deviceTypeId.android": "Android",
      "deviceForm.deviceTypeId.windows": "Windows",
      "deviceForm.deviceTypeId.brightsign": "Brightsign",
      "deviceForm.deviceTypeId.samsung": "SamsungSSP",
      "deviceForm.deviceTypeId.unknown": "Unknown",
      "deviceForm.deviceTypeId.moki": "MOKI",
      "deviceForm.serialNumber": "Serial Number",
      "deviceForm.serialNumber.unlocked": "Lock serial number editing",
      "deviceForm.serialNumber.locked": "Unlock serial number editing",

      "channels.cannotDeleteHasStreams":
        "The channel cannot be deleted because there are streams connected to it",
      "channels.title": "Channels",
      "channels.listTitle": "Channels",
      "channels.noResults": "No Channels Found",
      "channels.id": "Id",
      "channels.channelType": "Type",
      "channels.type.video": "Visual",
      "channels.type.music": "Music",
      "channels.type.vod": "VOD",
      "channels.type.onHold": "On-Hold",
      "channels.type.musicMessages": "Music Messages",
      "channels.type.unsupported": "Unsupported Channel Type",
      "channels.createVideo": "Visual Channel",
      "channels.createMusic": "Music Channel",
      "channels.createVOD": "VOD Channel",
      "channels.createOnHold": "On-Hold Channel",
      "channels.name": "Name",
      "channels.workgroupName": "Workgroup",
      "channels.workgroupId": "Workgroup Id",
      "channels.create": "Create channel",
      "channels.export": "Export",
      "channels.refresh": "Refresh",
      "channels.edit": "Edit channel",
      "channels.delete": "Delete Channel",
      "channels.deleted": "Channel was deleted successfully",
      "channels.deleteMessage":
        "Are you sure you want to delete the <b>{{name}}</b> channel? <br/> <b>This operation cannot be reverted!</b>",
      "channelForm.nav.streams": "Streams",
      "channelForm.createTitle": "New Channel",
      "channelForm.concurrency":
        "The channel you are editing has been changed and cannot be saved.",
      "channelForm.refresh": "Refresh channel data",

      "streams.title": "Streams",
      "streams.id": "Id",
      "streams.name": "Name",
      "streams.refresh": "Refresh",
      "streams.create": "Create",
      "streams.edit": "Edit",
      "streams.export": "Export",
      "streams.x": "X",
      "streams.y": "Y",
      "streams.width": "Width",
      "streams.height": "Height",
      "streams.exportFileName": "Streams",
      "streams.audioOutputType": "Audio Output",
      "streams.audioOutputType.all": "All zones",
      "streams.audioOutputType.left": "Left zone",
      "streams.audioOutputType.right": "Right zone",
      "streams.audioOutputType.unsupported": "Unsupported zone",
      "streams.hardwareName": "Device",
      "streams.channelName": "Channel",
      "streams.nav.selectChannel": "Channel/Playlist",
      "streams.nav.loadingFirstChannel": "Loading default channel",
      "streams.nav.noChannelSelected": "No channel selected",
      "streams.noResults": "No Streams Found",
      "streams.hardwareId": "Device Id",
      "streams.hardwareSerialNumber": "Device Serial Number",
      "streams.delete": "Delete Stream(s)",
      "streams.deleteSingle":
        "Are you sure you want to delete the <b>{{name}} stream?</b>  <br/> <b>This operation cannot be reverted!</b>",
      "streams.deleteMultiple":
        "Are you sure you want to delete the <b>{{count}} streams?</b> <br/> <b>This operation cannot be reverted!</b>",
      "streams.deletedSingle": "Stream deleted successfully",
      "streams.deletedMultiple": "Streams deleted successfully",
      "streamForm.createTitle": "New Stream",
      "streamForm.x": "X",
      "streamForm.y": "Y",
      "streamForm.width": "Width",
      "streamForm.height": "Height",
      "streamForm.channelName": "Channel",
      "streamForm.streamProperties": "Stream Properties",
      "streamForm.name": "Name",
      "streamForm.refresh": "Refresh stream data",

      "deviceSettings.showCritical": "Show critical settings",
      "deviceSettings.criticalSetting": "This setting is CRITICAL",
      "deviceSettings.value": "Value",
      "deviceSettings.isInherited": "Inherited",
      "deviceSettings.riskLevel": "Risk Level",
      "deviceSettings.riskLevelLabel": "Risk Level",
      "deviceSettings.timestamp": "Timestamp",
      "deviceSettings.origin": "Origin",
      "deviceSettings.username": "User",
      "deviceSettings.setting": "Setting",
      "deviceSettings.title": "Device Settings",
      "deviceSettings.valueDialogTitle": "Value for {{key}}",
      "deviceSettings.dangerZone": "DANGER ZONE",
      "deviceSettings.dangerZoneWarning":
        "You are about to modify a critical setting!",
      "deviceSettings.dangerZoneReadMe":
        "A wrong value may have irreversible impact on the devices!",
      "deviceSettings.dangerZoneMath":
        "Please answer the following math question to proceed:",
      "deviceSettings.dangerZoneCheckbox": "Yes, I understand the risk",
      "deviceSettings.applyingSettings": "Applying Settings",
      "deviceSettings.youCanStillCancel": "You can still cancel these changes",
      "deviceSettings.cancelChanges": "Cancel Changes",
      "deviceSettings.playerCountReadme":
        "By continuing, you are going to affect {{count}} devices.",
      "deviceSettings.playerCountDialogInfo":
        "Please type in the number of devices to confirm you want to proceed:",
      "deviceSettings.playerCountLabel": "Number of devices",
      "deviceSettings.saved": "Done",
      "deviceSettings.saveSuccessToast": "Changes pushed successfully",
      "deviceSettings.deleteOverride":
        "Delete overridden value and inherit from parent",
      "deviceSettings.returnToOverride": "Use overridden value",
      "deviceSettings.removeSetting": "Remove this setting from editting",
      "deviceSettings.summarySettingsForSavingPart1":
        "The following settings have changes and will be saved. Remove any setting by clicking the ",
      "deviceSettings.summarySettingsForSavingPart2": " button.",
      "deviceSettings.summarySettingsNotForSavingPart1":
        "These settings have not been changed and will not be saved. Add any setting as override by clicking the ",
      "deviceSettings.summarySettingsNotForSavingPart2": " button.",
      "deviceSettings.summaryRemoveSetting": "Remove setting",
      "deviceSettings.summaryAddSetting": "Add setting",
      "mathConfirmationDialog.math":
        "Please solve the following math question to continue",

      // Device Settings Keys
      securitySettings: "Security Settings",
      "securitySettings.vodAppPassword": "VOD App password",
      "securitySettings.playerConfigAppPassword": "Player Config App Password",
      "securitySettings.diagnosticsAppPassword": "Diagnostics App Password",
      "securitySettings.encryptMediaFiles": "Encrypt Media Files",

      siteSettings: "Site Settings",
      "siteSettings.siteId": "Site Id",
      "siteSettings.bandwidthSettings": "Bandwidth Settings",
      "siteSettings.sleepMode": "Sleep Mode",
      "siteSettings.openingHours": "Opening Hour",
      "siteSettings.customParamsMap1": "Custom Params Map 01",
      "siteSettings.customParamsMap2": "Custom Params Map 02",
      "siteSettings.customParamsMap3": "Custom Params Map 03",
      "siteSettings.customParamsMap4": "Custom Params Map 04",
      "siteSettings.customParamsMap5": "Custom Params Map 05",
      "siteSettings.customParamsMap6": "Custom Params Map 06",
      "siteSettings.customParamsMap7": "Custom Params Map 07",
      "siteSettings.customParamsMap8": "Custom Params Map 08",
      "siteSettings.customParamsMap9": "Custom Params Map 09",
      "siteSettings.customParamsMap10": "Custom Params Map 10",
      "siteSettings.customParamsMap11": "Custom Params Map 11",
      "siteSettings.customParamsMap12": "Custom Params Map 12",
      "siteSettings.customParamsMap13": "Custom Params Map 13",
      "siteSettings.customParamsMap14": "Custom Params Map 14",
      "siteSettings.customParamsMap15": "Custom Params Map 15",
      "siteSettings.contentDownloadWindows": "Content Download Window",
      "siteSettings.zipCode": "Zip Code",
      "siteSettings.longitude": "Longitude",
      "siteSettings.latitude": "Latitude",
      "siteSettings.maintenanceWindow": "Maintenance Window",

      cloudApiSettings: "Cloud API Settings",
      "cloudApiSettings.baseServerUpgradeUrl": "Base Server Upgrade URL",
      "cloudApiSettings.baseServerApiUrl": "Base Server API URL",

      contentSettings: "Content Settings",
      "contentSettings.storedTracksCountLimit": "Stored Tracks Count Limit",
      "contentSettings.networkNotAvailableMessage":
        "Network Not Available Message",

      platformSettings: "Platform Settings",
      "platformSettings.zidooSoundOutputRaw": "Zidoo Sound Output Raw",
      "platformSettings.usePowerKeyForScreenOff":
        "Use Power Key For Screen Off",
      "platformSettings.adbEnabled": "ADB Enabled",
      "platformSettings.wsusUrl": "WSUS URL",

      serialDisplayCommands: "Serial Display Commands",
      "serialDisplayCommands.commandsMap": "Commands Map",

      soundSettings: "Sound Settings",
      "soundSettings.volumeLevels": "Volume Levels",

      hardwareSettings: "Hardware Settings",
      "hardwareSettings.serial": "Serial",
      "hardwareSettings.deviceModel": "Device Model",
      "hardwareSettings.networkHostname": "Network Hostname",
      "hardwareSettings.knownSerialProductIds": "Known Serial Product Ids",
      "hardwareSettings.earlyAdopter": "Early Adopter",

      softwareSettings: "Software Settings",
      "softwareSettings.headlessSetupEnabled": "Headless Setup Enabled",
      "softwareSettings.liveCommandsEnabled": "Live Commands Enabled",
      "softwareSettings.earlyAdopter": "Early Adopter",
      "softwareSettings.httpServiceMaxWorkers": "HTTP Service Max Workers",
      "softwareSettings.projectId": "Project ID",

      resetSettings: "Reset Settings",
      "softwareSettings.resetSettings.resetMode": "Reset Mode",
      "softwareSettings.resetSettings.resetTime": "Reset Time",
      "softwareSettings.resetSettings.exceptions": "Reset Exceptions",

      debugSettings: "Debug Settings",
      "softwareSettings.debugSettings.streamMusicTracks": "Stream Music Tracks",
      "softwareSettings.debugSettings.networkSetupDisabled":
        "Disable Network Setup",
      "softwareSettings.debugSettings.playerFilesUpdateDisabled":
        "Disable Player Files Update",
      "softwareSettings.debugSettings.deleteMediaOnLaunch":
        "Delete Media on Launch",
      "softwareSettings.debugSettings.deletePlaylistOnLaunch":
        "Delete Playlist On Launch",
      "softwareSettings.debugSettings.playbackLogsUploadDisabled":
        "Disable Playback Logs Upload",
      "softwareSettings.debugSettings.monitoringDisabled": "Disable Monitoring",
      "softwareSettings.debugSettings.useHeadlessSetupWebAppDevBuild":
        "Use Headless Setup WebApp Dev Build",
      "softwareSettings.debugSettings.crashLoggerEnabled":
        "Enable Crash Logger",
      "softwareSettings.debugSettings.debugViewVisible": "Debug View Visible",

      watchdogSettings: "Watchdog Settings",
      "watchdogSettings.playbackRestartTimeoutMillis":
        "Playback Restart Timeout (ms)",
      "watchdogSettings.watchdogEnabled": "Watchdog Enabled",

      displaySettings: "Display Settings",
      "displaySettings.displayScale": "Scale",
      "displaySettings.displayResolution": "Resolution",
      "displaySettings.displayOrientation": "Orientation",
      "displaySettings.displayBrightness": "Brightness",
      "displaySettings.displayDensity": "Density",

      purgeSettings: "Purge Settings",
      "contentSettings.purgeSettings.mediaFilesPurgeUntouchedDays":
        "Purge media files untouched # days",
      "contentSettings.purgeSettings.logsPurgeUntouchedDays":
        "Purge logs untouched # days",
      "contentSettings.purgeSettings.statisticsPurgeUntouchedDays":
        "Purge statistics untouched # days",
      "contentSettings.purgeSettings.customDirectories": "Custom Directories",
      "contentSettings.purgeSettings.musicTracksPurgeUntouchedDays":
        "Music tracks purge untouched # days",

      loggingSettings: "Logging Settings",
      "loggingSettings.activityLogsAutoUploadEnabled":
        "Enable Activity Logs Auto Upload",
      "loggingSettings.activityLogsAutoUploadIntervalMillis":
        "Auto Upload Activity Logs Interval (ms)",
      "loggingSettings.playbackLogsFileEnabled": "Enable Playback Logs File",
      "loggingSettings.activityLogsFileEnabled": "Enable Activity Logs File",
      "loggingSettings.activityLogsBufferMaxSizeBytes":
        "Activity Logs Buffer Max Size (bytes)",

      cronSettings: "Cron Settings",
      "cronSettings.monitorDataUploadIntervalSeconds":
        "Monitor Data Upload Interval (s)",
      "cronSettings.playlistFeedsUpdateIntervalSeconds":
        "Playlist Feeds Update Interval (s)",
      "cronSettings.playlistUpdateIntervalSeconds":
        "Playlist Update Interval (s)",
      "cronSettings.playerFilesDownloadIntervalSeconds":
        "Player Files Download Interval (s)",

      clientSettings: "Client Settings",
      "clientSettings.projectId": "Project Id",
      "clientSettings.earlyAdopter": "Early Adopter",

      timeSettings: "Time Settings",
      "timeSettings.timeZone": "Timezone",

      timeSyncSettings: "Time Sync Settings",
      "timeSettings.timeSyncSettings.syncMode": "Sync Mode",
      "timeSettings.timeSyncSettings.customNtpServerUrl":
        "Custom NTP Server URL",

      playbackSettings: "Playback Settings",
      "playbackSettings.multiZoneNoMediaFillColor":
        "Multizone No Media Fill Color",
      "playbackSettings.vodSettings.mediaListZoneFrameLandscape":
        "Media List Zone Frame Landscape",
      "playbackSettings.syncPlaybackTimeoutMillis":
        "Playback Sync Timeout (ms)",
      "playbackSettings.playlistUpdateInstantReload":
        "Instant Reload on Playlist Update",
      "playbackSettings.forceReportNowPlayingData":
        "Force Report Now Playing Data",

      flashMediaSettings: "Flash Media Settings",
      "playbackSettings.flashMediaSettings.useAirRuntime": "Use AIR Runtime",

      vodSettings: "VOD Settings",
      "playbackSettings.vodSettings.mediaFrameFocusBackgroundColor":
        "Media Frame Focus BG Color",
      "playbackSettings.vodSettings.mediaTitleTextColor":
        "Media Title Text Color",
      "playbackSettings.vodSettings.mediaTitleTextColorFocused":
        "Media Title Focused Text Color",
      "playbackSettings.vodSettings.isHintEnable": "Enable Hint",
      "playbackSettings.vodSettings.mediaFrameBackgroundColor":
        "Media Frame BG Color",
      "playbackSettings.vodSettings.timeoutIntervalSeconds":
        "Timeout Interval (s)",
      "playbackSettings.vodSettings.mediaListZoneFramePortrait":
        "Media List Zone Frame Portrait",

      videoSettings: "Video Settings",
      "playbackSettings.videoSettings.videoPlayerType": "Player Type",
      "playbackSettings.videoSettings.videoRenderingMode": "Rendering Mode",

      webPageSettings: "Web Page Settings",
      "playbackSettings.webPageSettings.purgeWebCacheOnStart":
        "Purge Cache on Start",
      "playbackSettings.webPageSettings.crosswalkUseTextureView":
        "Use Crosswalk Texture View",
      "playbackSettings.webPageSettings.allowedUrls": "Allowed URLs",

      audioSettings: "Audio Settings",
      "playbackSettings.audioSettings.duckingDuration": "Ducking Duration",
      "playbackSettings.audioSettings.duckingPercent": "Ducking (%)",
      "playbackSettings.audioSettings.musicCrossfadeMode":
        "Music Crossfade Mode",
      "playbackSettings.audioSettings.audioPlayerType": "Player Type",
      "playbackSettings.audioSettings.musicTracksVolume": "Music Tracks Volume",
      "playbackSettings.audioSettings.musicSmoothStopDurationMillis":
        "Music Smooth Stop Duration (ms)",
      "playbackSettings.audioSettings.musicStaticCrossfadeDurationMillis":
        "Music Static Crossfade Duration (ms)",
      "playbackSettings.audioSettings.musicSmoothStopPercent":
        "Music Smooth Stop (%)",
      "playbackSettings.audioSettings.volumePercentFactorZoneLeft":
        "Volume Percent Factor Zone Left",
      "playbackSettings.audioSettings.volumePercentFactorZoneRight":
        "Volume Percent Factor Zone Right",
      "playbackSettings.audioSettings.musicTracksVolumeZoneLeft":
        "Music Tracks Volume Left Zone",
      "playbackSettings.audioSettings.musicTracksVolumeZoneRight":
        "Music Tracks Volume Right Zone",

      playbackInteractionSettings: "Playback Interaction Settings",
      "playbackSettings.playbackInteractionSettings.onTouchAction":
        "On Touch Action",

      appCommunicationSettings: "App Communication Settings",

      multicastSettings: "Multicast Settings",
      "appCommunicationSettings.multicastSettings.defaultMulticastPort":
        "Default Port",
      "appCommunicationSettings.multicastSettings.defaultMulticastAddress":
        "Default Address",

      ipcSettings: "IPC Settings",
      "appCommunicationSettings.ipcSettings.tcpPort": "TCP Port",
      "appCommunicationSettings.ipcSettings.datagramReceivePort":
        "Datagram Receive Port",
      "appCommunicationSettings.ipcSettings.datagramSendPort":
        "Datagram Send Port",

      p2pSettings: "P2P Settings",
      "appCommunicationSettings.p2pSettings.port": "Port",
      "appCommunicationSettings.p2pSettings.clientEnabled": "Enable Client",
      "appCommunicationSettings.p2pSettings.serverEnabled": "Enable Server",

      networkSettings: "Network Settings",
      wifiSettings: "Wifi Settings",
      "networkSettings.wifiSettings.allowConfigurationChange":
        "Allow Configuration Change",
      networkLinkSettings: "Network Link Settings",
      wifiAccessPointSettings: "Wifi Access Point Settings",
      "networkSettings.wifiSettings.wifiAccessPointSettings.ssid": "SSID",
      "networkSettings.wifiSettings.wifiAccessPointSettings.password":
        "Password",
      "networkSettings.wifiSettings.wifiAccessPointSettings.authenticationType":
        "Authentication Type",
      "networkSettings.wifiSettings.dhcpEnabled": "Enable DHCP",
      "networkSettings.wifiSettings.networkLinkSettings.dns1": "DNS1",
      "networkSettings.wifiSettings.networkLinkSettings.dns2": "DNS2",
      "networkSettings.wifiSettings.networkLinkSettings.gateway": "Gateway",
      "networkSettings.wifiSettings.networkLinkSettings.ipAddress":
        "IP Address",
      "networkSettings.wifiSettings.networkLinkSettings.netmask": "Netmask",
      "networkSettings.wifiSettings.interfaceEnabled": "Interface Enabled",
      "networkSettings.wifiSettings.vlanConfigurations": "VLAN Configurations",

      httpProxySettings: "HTTP Proxy Settings",
      "networkSettings.httpProxySettings.port": "Port",
      "networkSettings.httpProxySettings.host": "Host",
      "networkSettings.httpProxySettings.proxyEnabled": "Enable Proxy",
      "networkSettings.httpProxySettings.excludedHostsList": "Excluded Hosts",
      "networkSettings.httpProxySettings.password": "Password",
      "networkSettings.httpProxySettings.username": "Username",

      ethernetSettings: "Ethernet Settings",
      "networkSettings.ethernetSettings.dhcpEnabled": "Enable DHCP",
      "networkSettings.ethernetSettings.vlanConfigurations":
        "VLAN Configurations",
      "networkSettings.ethernetSettings.allowConfigurationChange":
        "Allow Configuration Change",
      "networkSettings.ethernetSettings.interfaceEnabled": "Interface Enabled",
      "networkSettings.ethernetSettings.networkLinkSettings.dns1": "DNS1",
      "networkSettings.ethernetSettings.networkLinkSettings.dns2": "DNS2",
      "networkSettings.ethernetSettings.networkLinkSettings.gateway": "Gateway",
      "networkSettings.ethernetSettings.networkLinkSettings.ipAddress":
        "IP Address",
      "networkSettings.ethernetSettings.networkLinkSettings.netmask": "Netmask",
      "syncSettings.lastPlayerChangeId": "Last Player Change ID",

      "settingsEditor.propertiesCountMultiple": "{{count}} properties",
      "settingsEditor.propertiesCountSingle": "1 property",
      "settingsEditor.itemsCountMultiple": "{{count}} items",
      "settingsEditor.itemsCountSingle": "1 item",
      "settingsEditor.propertiesCount": "{{count}} properties",
      "settingsEditor.propertyKey": "Property key",
      "settingsEditor.dialog.editKey": "Edit key",
      "settingsEditor.dialog.addKey": "Add key",
      "settingsEditor.button.editKey": "Edit key",
      "settingsEditor.button.addItem": "Add item",
      "settingsEditor.button.cloneItem": "Clone item",
      "settingsEditor.button.removeItem": "Remove item",
      "settingsEditor.unlock": "Unlock device setting",
      "settingsEditor.validation.required": "Value cannot be empty",
      "settingsEditor.validation.greaterThan":
        "Value must be greater than {{value}}",
      "settingsEditor.validation.lessThan": "Value must be less than {{value}}",
      "settingsEditor.validation.greaterOrEqualThan":
        "Value must be greater than or equal to {{value}}",
      "settingsEditor.validation.lessOrEqualThan":
        "Value must be less than or equal to {{value}}",
      "settingsEditor.validation.pattern": "Incorrect value format",
      "settingsEditor.validation.longerThan":
        "Value must be longer than {{value}} characters",
      "settingsEditor.validation.shorterThan":
        "Value must be shorter than {{value}} characters",
      "settingsEditor.riskLevel0": "Critical",
      "settingsEditor.riskLevel1": "High",
      "settingsEditor.riskLevel2": "Medium",
      "settingsEditor.riskLevel3": "Low",
      "settingsEditor.riskLevel4": "No risk",
      settingValueSearch: "Contains",

      "wizard.title": "Edit Settings",
      "wizard.settingsStepper.back": "Back",
      "wizard.settingsStepper.next": "Next",
      "wizard.settingsStepper.save": "Save",
      "wizard.settingsStepper.settingsSelector": "Select Settings",
      "wizard.settingsStepper.setValues": "Set Values",
      "wizard.settingsStepper.setTarget": "Set Target",
      "settingsSelector.setting": "Setting",
      "settingsSelector.value": "Value",
      "wizard.settingsStepper.cancel": "Cancel",
      "wizard.dirtyDialog.unsavedChangesMessage1":
        "Are you sure you want to go back?",
      "wizard.dirtyDialog.unsavedChangesMessage2":
        "The changes you have made will be lost.",
      "wizard.dirtyDialog.title": "Back to settings list",
      "wizard.dirtyDialog.andmore": "...and {{count}} more",
      "wizard.dirtyDialog.listHeader": "You have changed",
    },
  },
  fr: {
    translation: {},
  },
  de: {
    translation: {},
  },
};

import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGridApi } from "react-tools";

import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";

import { useStyles } from "./ExternalFilterFooter.jss";

export interface ExternalFilterFooterProps {
  label: {
    activeText: (total: number, filtered: number, visible: number) => string;
    inactiveText: (total: number, filtered: number, visible: number) => string;
    button: string;
  };
  reverse?: boolean;
  hideButton?: boolean;
}

const getCount = (gridApi: any) => {
  let total = 0;
  let externalFiltered = 0;
  const filterFn =
    gridApi?.gridOptionsWrapper?.gridOptions?.doesExternalFilterPass;
  if (!filterFn) {
    return {
      total: gridApi.gridOptionsWrapper?.gridOptions?.rowData.length || total,
      externalFiltered: gridApi.gridOptionsWrapper?.gridOptions?.rowData.length || externalFiltered,
    };
  }
  gridApi.forEachNode((row: any) => {
    total++;
    if (filterFn(row)) {
      externalFiltered++;
    }
  });
  return { total, externalFiltered };
};

export const ExternalFilterFooter = (props: ExternalFilterFooterProps) => {
  const classes = useStyles();
  const [val, setVal] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [externalFilteredRows, setExternalFilteredRows] = useState(0);
  const [visibleRows, setVisibleRows] = useState(0);
  const gridApi = useGridApi() as any;

  const toggleVal = useCallback(() => setVal(!val), [val, setVal]);

  useEffect(() => {
    if (!gridApi) {
      return;
    }

    const gridOptions = gridApi.gridOptionsWrapper?.gridOptions;

    if (
      gridOptions?.isExternalFilterPresent &&
      typeof gridOptions.isExternalFilterPresent === "function" &&
      gridOptions.isExternalFilterPresent() !== (props.reverse ? !val : val)
    ) {
      gridOptions.isExternalFilterPresent = () =>
        props.reverse ? !val : val;
    }

    gridApi.onFilterChanged();
  }, [gridApi, gridApi?.gridOptionsWrapper?.gridOptions.rowData, val, props.reverse]);

  useEffect(() => {
    setVisibleRows(gridApi ? gridApi.getDisplayedRowCount() : 0);
    gridApi?.addEventListener("paginationChanged", (_: any) => {
      setVisibleRows(gridApi.getDisplayedRowCount());
    });
  }, [gridApi]);

  useEffect(() => {
    if (!gridApi) {
      return;
    }

    const { total, externalFiltered } = getCount(gridApi);
    setTotalRows(total);
    setExternalFilteredRows(externalFiltered);

    gridApi.addEventListener("rowDataChanged", () => {
      const { total, externalFiltered } = getCount(gridApi);
      setTotalRows(total);
      setExternalFilteredRows(externalFiltered);
    });
  }, [gridApi]);

  let visibleLabel = "";
  if (props.reverse) {
    visibleLabel = !val
      ? props.label.activeText(totalRows, externalFilteredRows, visibleRows)
      : props.label.inactiveText(totalRows, externalFilteredRows, visibleRows);
  } else {
    visibleLabel = val
      ? props.label.activeText(totalRows, externalFilteredRows, visibleRows)
      : props.label.inactiveText(totalRows, externalFilteredRows, visibleRows);
  }

  return (
    <>
      <Typography className={classes.grow} variant={"body2"}>
        {visibleLabel}
      </Typography>
      {!props.hideButton && (
        <FormControlLabel
          className={classes.noMarginFormLabel}
          control={<Checkbox onChange={toggleVal} checked={val} />}
          label={props.label.button}
        />
      )}
    </>
  );
};

export const CountFooter = () => {
  return (
    <ExternalFilterFooter
      label={{
        activeText: (t, f, v) => `Showing ${v} of ${t}`,
        inactiveText: (t, f, v) => `Showing ${v} of ${t}`,
        button: "",
      }}
      hideButton
      reverse={true}
    />
  );
};

export const WorkgroupFilterFooter = () => {
  const [tr] = useTranslation();
  return (
    <ExternalFilterFooter
      label={{
        activeText: (t, f, v) =>
          `Showing ${v} of ${f} (up to ${t} including subworkgroups)`,
        inactiveText: (t, f, v) =>
          `Showing ${v} of ${t} (subworkgroups included)`,
        button: tr("workgroupLocations.recursive"),
      }}
      reverse={true}
    />
  );
};

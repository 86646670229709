import React, { useCallback, useEffect, useState } from "react";
import { NormalizedIdNames } from "models";
import { Workgroup } from "models";
import { WorkgroupTreeToolbar } from "./workgroup-tree-toolbar";
import { TreeContainer, NormalizedTreeItemActions } from "react-tools";
import { FlexFadeIn } from "components";
import { TreeContentLoader } from "./loader";
import { useWorkgroupTreeItemActions } from "./hooks";

export interface WorkgroupTreeActions {
  fetchWorkgroups: () => void;
  toggleFavorite: (workgroupId: number, newStatus: boolean) => void;
  collapseAll: () => void;
  expandAll: () => void;
  searchWorkgroups: (searchText: string, arrayFilter?: number[]) => void;
  openWorkgroupDetails: (workgroupId: number, workgroupName: string) => void;
  openWorkgroupNew: (parentWorkgroupId: number) => void;
}

export interface WorkgroupTreeProps {
  workgroups: NormalizedIdNames<Workgroup>;
  favoriteWorkgroups: number[];
  treeActionState: NormalizedTreeItemActions;
  workgroupsFetching: boolean;
  treeName: string;
  isFetchingWorkgroup: boolean;
  isDeletingWorkgroup: boolean;
}

type Props = WorkgroupTreeActions & WorkgroupTreeProps;

export const WorkgroupTree = (props: Props) => {
  const { fetchWorkgroups } = props;
  const [searchText, setSearchText] = useState("");
  const [showOnlyFavorites, setShowOnlyFavorites] = useState(false);

  const treeItemActions = useWorkgroupTreeItemActions(
    props.treeActionState,
    props.favoriteWorkgroups,
    props.toggleFavorite,
    props.openWorkgroupNew
  );

  useEffect(() => {
    fetchWorkgroups();
  }, []);

  const handleWorkgroupSelect = useCallback(
    (modelId: number) =>
      props.openWorkgroupDetails(modelId, props.workgroups[modelId].name),
    [props.workgroups]
  );

  const filterFavoriteWorkgroups = useCallback(() => {
    const newShowOnlyFavorites = !showOnlyFavorites;
    setShowOnlyFavorites(newShowOnlyFavorites);
    searchWorkgroups(searchText, newShowOnlyFavorites);
  }, [props.favoriteWorkgroups, showOnlyFavorites, searchText]);

  const filterWorkgroups = useCallback(
    (newSearchText: string) => {
      setSearchText(newSearchText);
      searchWorkgroups(newSearchText, showOnlyFavorites);
    },
    [props.favoriteWorkgroups, showOnlyFavorites, showOnlyFavorites]
  );

  const searchWorkgroups = useCallback(
    (newSearchText: string, showFavorites: boolean) => {
      const favorites = showFavorites ? props.favoriteWorkgroups : [];
      props.searchWorkgroups(newSearchText, favorites);
    },
    [props.favoriteWorkgroups]
  );

  useEffect(() => {
    if (props.workgroups && searchText) {
      searchWorkgroups(searchText, showOnlyFavorites);
    }
  }, [props.workgroups, searchText, searchWorkgroups, showOnlyFavorites]);

  return (
    <>
      <WorkgroupTreeToolbar
        collapseAll={props.collapseAll}
        expandAll={props.expandAll}
        handleFavoriteButtonClick={filterFavoriteWorkgroups}
        searchWorkgroups={filterWorkgroups}
        loading={props.workgroupsFetching}
      />

      {(props.isDeletingWorkgroup || props.workgroupsFetching) && <TreeContentLoader />}

      {!(props.isDeletingWorkgroup || props.workgroupsFetching) && (
        <FlexFadeIn>
          <TreeContainer
            noDataMessage={"No Workgroups found"}
            treeName={props.treeName}
            onSelect={handleWorkgroupSelect}
            actions={treeItemActions}
            disableSelection={props.isFetchingWorkgroup}
          />
        </FlexFadeIn>
      )}
    </>
  );
};

import { WorkgroupLocation } from "models";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ButtonItem, useGridExportEvents } from "react-tools";

import Add from "@material-ui/icons/Add";
import CloudDownload from "@material-ui/icons/CloudDownload";
import Refresh from "@material-ui/icons/Refresh";
import Delete from "@material-ui/icons/Delete";

export const useLocationListBladeButtons = (
  fetchLocations: () => void,
  createLocation: () => void,
  confirmDelete: () => void,
  selected: WorkgroupLocation[],
  loading: boolean,
  gridApi: any
) => {
  const exportFunctions = useGridExportEvents();
  const [t] = useTranslation();

  const buttons = useMemo<ButtonItem[]>(
    () => [
      {
        tooltip: "workgroupLocations.refresh",
        icon: () => <Refresh />,
        onClick: fetchLocations,
        disabled: loading,
      },
      {
        tooltip: "workgroupLocations.delete",
        icon: () => <Delete />,
        disabled: selected ? selected.length === 0 : true,
        onClick: confirmDelete,
      },
      {
        tooltip: "workgroupLocations.create",
        icon: () => <Add />,
        onClick: createLocation,
      },

      {
        tooltip: "workgroupLocations.export",
        icon: () => <CloudDownload />,
        onClick: () => {
          if (exportFunctions && exportFunctions.exportCsv) {
            exportFunctions.exportCsv(
              t("workgroupLocations.exportFileName").toString()
            );
          }
        },
      },
    ],
    [selected, loading, gridApi]
  );

  return buttons;
};

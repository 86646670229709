import {
  DeviceSettingsBladeName,
  DeviceSettingsContainerProps,
} from "device-settings/list/device-settings.container";
import { DevicesBladeName } from "device/list/devices.container";
import i18n from "localization/i18n";
import { WorkgroupLocation } from "models";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BladeActions,
  BladeConfig,
  BladeProps,
  IdName,
  openBlade,
  withRegisterBlade,
  EntityType,
} from "react-tools";

import { actions, selectors, thunks } from "../duck";
import { LocationForm } from "./location-form";

export const LocationFormBladeName = "LOCATION_FORM_BLADE_NAME";

export interface LocationFormContainerProps {
  workgroup: IdName;
  locationId?: number;
  locationName: string;
}

const bladeConfig: BladeConfig<LocationFormContainerProps> = {
  bladeType: LocationFormBladeName,
  size: { defaultWidth: 460, minWidth: 400 },
  title: (props: LocationFormContainerProps) =>
    props.locationId ? props.locationName : i18n.t("locationForm.createTitle"),
  allowMultipleInstances: false,
};

const ContainerComponent = (props: LocationFormContainerProps & BladeProps) => {
  const { workgroup, locationId, locationName, ...bladeProps } = props;

  const dispatch = useDispatch();

  const closeBlade = useCallback(
    () => dispatch(BladeActions.closeBlade(props.bladeId)),
    [props.bladeId]
  );

  const onClose = useCallback(
    () => dispatch(actions.clearLocationDetails()),
    [dispatch]
  );

  const fetchLocation = useCallback(
    (locationId: number) => dispatch(thunks.fetchLocationAsync(locationId)),
    []
  );

  const location = useSelector(selectors.selectLocation);
  const isFetchingLocation = useSelector(selectors.selectIsFetchingLocation);
  const isSavingLocation = useSelector(selectors.selectIsSavingLocation);
  const savingErrorCode = useSelector(selectors.selectSaveErrorStatusCode);

  const saveLocation = useCallback(
    (location: WorkgroupLocation) =>
      dispatch(
        thunks.saveLocationAsync(props.bladeId, location, props.workgroup)
      ),
    [dispatch, props.workgroup.id]
  );

  const openLocationDevices = useCallback(
    (workgroupId: number, workgroupName: string) =>
      dispatch(
        BladeActions.closeChildrenBlades(
          props.bladeId,
          openBlade(props.bladeId, DevicesBladeName, {
            location: { id: props.locationId, name: props.locationName },
            workgroup: { id: workgroupId, name: workgroupName },
          })
        )
      ),
    [props.locationId, props.locationName]
  );

  const openLocationDeviceSettings = useCallback(
    (location: IdName) =>
      dispatch(
        BladeActions.closeChildrenBlades(
          props.bladeId,
          openBlade<DeviceSettingsContainerProps>(
            props.bladeId,
            DeviceSettingsBladeName,
            {
              location,
              parentEntity: {
                entityId: workgroup.id,
                entityType: EntityType.Workgroup,
              },
            }
          )
        )
      ),
    []
  );

  return (
    <LocationForm
      {...bladeProps}
      savingErrorCode={savingErrorCode}
      isSavingLocation={isSavingLocation}
      isFetchingLocation={isFetchingLocation}
      workgroup={workgroup}
      locationId={locationId}
      saveLocation={saveLocation}
      openLocationDevices={openLocationDevices}
      openLocationDeviceSettings={openLocationDeviceSettings}
      closeBlade={closeBlade}
      onClose={onClose}
      fetchLocation={fetchLocation}
      location={location}
      locationName={locationName}
    />
  );
};

export const LocationFormContainer = withRegisterBlade<
  LocationFormContainerProps & BladeProps
>(bladeConfig)(ContainerComponent);

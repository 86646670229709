import { TreeSelectors, denormalize } from "react-tools";
import { createSelector } from "reselect";

import { areaName, WorkgroupState } from "./types";
import { canDeleteWorkgroup } from "utils";
import { NormalizedTreeItems } from "react-tools/build/tree/duck/types";
import { NormalizedIdNames, Workgroup } from "models";

const selectWorkgroupState = (state: any): WorkgroupState =>
  state[areaName] as WorkgroupState;

export const selectFavoriteWorkgroups = createSelector(
  [selectWorkgroupState],
  (state: WorkgroupState) => state.favoriteWorkgroups
);

export const selectIsFetchingWorkgroups = createSelector(
  [selectWorkgroupState],
  (state: WorkgroupState) => state.isFetchingWorkgroups
);

export const selectWorkgroups = createSelector(
  [selectWorkgroupState],
  (state: WorkgroupState) => state.workgroups
);

export const selectWorkgroupById = (id: number) =>
  createSelector([selectWorkgroups], (workgroups) => workgroups[id]);

export const selectCanDeleteWorkgroup = (id?: number) =>
  createSelector(
    [TreeSelectors.selectTreeItems, selectWorkgroups],
    (
      treeItems: NormalizedTreeItems,
      workgroups: NormalizedIdNames<Workgroup>
    ) => {
      if (!id) {
        return false;
      }

      const workgroupTree = denormalize(treeItems);
      const rootWorkgroup = workgroupTree.find((e) => e.modelId === id);

      const canDelete = canDeleteWorkgroup(
        rootWorkgroup,
        workgroupTree,
        workgroups
      );

      return canDelete;
    }
  );

export const selectIsDeletingWorkgroup = createSelector(
  [selectWorkgroupState],
  (state) => state.workgroupDetails.isDeleting
);

export const selectWorkgroupDetailsIsSaving = createSelector(
  [selectWorkgroupState],
  (state: WorkgroupState) => state.workgroupDetails.isSaving
);

export const selectWorkgroupDetailsSaved = createSelector(
  [selectWorkgroupState],
  (state: WorkgroupState) => state.workgroupDetails.saved
);

export const selectWorkgroupDetails = createSelector(
  [selectWorkgroupState],
  (state: WorkgroupState) => state.workgroupDetails.model
);

export const selectIsFetchingWorkgroup = createSelector(
  [selectWorkgroupState],
  (state: WorkgroupState) => state.workgroupDetails.isFetching
);

export const selectIsSavingWorkgroup = createSelector(
  [selectWorkgroupState],
  (state: WorkgroupState) => state.workgroupDetails.isSaving
);

export const selectIsFetchingTemplates = createSelector(
  [selectWorkgroupState],
  (state: WorkgroupState) => state.isFetchingTemplates
);

export const selectTemplates = createSelector(
  [selectWorkgroupState],
  (state: WorkgroupState) => state.templates
);

export const selectWorkgroupTemplates = createSelector(
  [selectWorkgroupState],
  (state: WorkgroupState) => state.workgroupDetails.templates
);

export const selectRootWorkgroupId = createSelector(
  [selectWorkgroups],
  (workgroups) => {
    const keys = Object.keys(workgroups);
    if (keys.length) {
      return workgroups[parseInt(keys[0])].id;
    }

    return null;
  }
);

import { ChannelsBladeName } from "channel/list/channels.container";
import { WORKGROUP_TREE_NAME } from "../../constants";
import { DeviceSettingsBladeName } from "device-settings/list/device-settings.container";
import { DevicesBladeName } from "device/list/devices.container";
import i18n from "localization/i18n";
import { WorkgroupLocationsBladeName } from "location/list/locations.container";
import { WorkgroupDetailsModel } from "models";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { batch, useDispatch, useSelector } from "react-redux";
import {
  BladeActions,
  BladeConfig,
  BladeProps,
  EntityType,
  withRegisterBlade,
} from "react-tools";
import { thunks } from "workgroup/duck";
import { WorkgroupDetailsMode } from "workgroup/duck/types";

import { actions, selectors } from "../duck";
import { useOpenChildBlade, useOpenTemplateLibraryBlade } from "./hooks";
import { getEmptyCompany, WorkgroupDetails } from "./workgroup-details";

export const WorkgroupDetailsBladeName = "WORKGROUP_DETAILS";

const title = (props: WorkgroupDetailsContainerProps) =>
  props.mode === WorkgroupDetailsMode.NEW
    ? i18n.t("workgroupDetails.new.title")
    : props.workgroupName;

const bladeConfig: BladeConfig<any> = {
  bladeType: WorkgroupDetailsBladeName,
  size: { defaultWidth: 530, minWidth: 530 },
  title: title,
  allowMultipleInstances: false,
};

export interface WorkgroupDetailsContainerProps {
  mode: WorkgroupDetailsMode;

  /** 
    Used on edit mode 
  */
  workgroupId: number;

  /** 
    Used on edit move  
  */
  workgroupName: string;

  /**  
    Used on new mode 
  */
  parentWorkgroupId: number;

  onClose?: () => void;
}

const ContainerComponent: React.FC<
  WorkgroupDetailsContainerProps & BladeProps
> = (props) => {
  const [t] = useTranslation();

  const dispatch = useDispatch();

  const model = useSelector(selectors.selectWorkgroupDetails);
  const isFetchingWorkgroup = useSelector(selectors.selectIsFetchingWorkgroup);
  const isDeletingWorkgroup = useSelector(selectors.selectIsDeletingWorkgroup);
  const isSaving = useSelector(selectors.selectWorkgroupDetailsIsSaving);
  const saved = useSelector(selectors.selectWorkgroupDetailsSaved);
  const workgroupTemplates = useSelector(selectors.selectWorkgroupTemplates);
  const rootWorkgroupId = useSelector(selectors.selectRootWorkgroupId);
  const canDeleteWorkgroup = useSelector((state) =>
    selectors.selectCanDeleteWorkgroup(model ? model.id : undefined)(
      state,
      WORKGROUP_TREE_NAME
    )
  );

  const [workgroup, setWorkgroup] = useState<WorkgroupDetailsModel>(
    model
      ? model
      : ({
          id: 0,
          name: "",
          parentId: props.parentWorkgroupId,
          isCompany: false,
          ...getEmptyCompany(),
        } as WorkgroupDetailsModel)
  );

  useEffect(() => {
    if (model) {
      setWorkgroup(model);
      return;
    }

    setWorkgroup({
      id: 0,
      name: "",
      parentId: props.parentWorkgroupId,
      isCompany: false,
      ...getEmptyCompany(),
    } as WorkgroupDetailsModel);
  }, [model]);

  const saveWorkgroup = (
    workgroup: WorkgroupDetailsModel,
    mode: WorkgroupDetailsMode
  ) => {
    dispatch(
      thunks.saveWorkgroupAsync(
        {
          ...workgroup,
          templates: workgroupTemplates,
        },
        mode
      )
    );
  };

  const openDeviceSettings = useOpenChildBlade(
    props.bladeId,
    DeviceSettingsBladeName,
    {
      parentEntity:
        model && model.parentId
          ? { entityId: model.parentId, entityType: EntityType.Workgroup }
          : null,
    },
    [model?.parentId]
  );
  const openWorkgroupDevices = useOpenChildBlade(
    props.bladeId,
    DevicesBladeName,
    {},
    []
  );
  const openWorkgroupLocations = useOpenChildBlade(
    props.bladeId,
    WorkgroupLocationsBladeName,
    {},
    []
  );

  const openWorkgroupChannels = useOpenChildBlade(
    props.bladeId,
    ChannelsBladeName,
    {},
    []
  );

  const getWorkgroup = useCallback(
    (workgroupId: number) => dispatch(thunks.fetchWorkgroupAsync(workgroupId)),
    []
  );

  const setTitle = useCallback(
    (bladeId: string, title: string) =>
      dispatch(BladeActions.setTitle(bladeId, title)),
    []
  );

  const onClose = useCallback(
    () =>
      batch(() => {
        dispatch(actions.clearWorkgroupDetails());
        if (props.onClose) {
          props.onClose();
        }
      }),
    [props.bladeId]
  );

  const closeBlade = useCallback(
    () => dispatch(BladeActions.closeBlade(props.bladeId)),
    [props.bladeId]
  );
  const openTemplateLibrarySelection = useOpenTemplateLibraryBlade(
    props.bladeId,
    [workgroup]
  );

  const deleteWorkgroup = useCallback(
    (workgroupId: number) => {
      dispatch(thunks.deleteWorkgroupAsync(workgroupId, props.bladeId));
    },
    [props.bladeId]
  );

  return (
    <WorkgroupDetails
      {...props}
      workgroupId={workgroup.id ? workgroup.id : props.workgroupId}
      mode={workgroup.id ? WorkgroupDetailsMode.EDIT : props.mode}
      closeBlade={closeBlade}
      onClose={onClose}
      isFetchingWorkgroup={isFetchingWorkgroup}
      isDeletingWorkgroup={isDeletingWorkgroup}
      isSaving={isSaving}
      saved={saved}
      saveWorkgroup={saveWorkgroup}
      openTemplateLibrarySelection={openTemplateLibrarySelection}
      openWorkgroupLocations={openWorkgroupLocations}
      openWorkgroupDevices={openWorkgroupDevices}
      openDeviceSettings={openDeviceSettings}
      openWorkgroupChannels={openWorkgroupChannels}
      getWorkgroup={getWorkgroup}
      setTitle={setTitle}
      workgroupTemplates={workgroupTemplates}
      model={workgroup}
      isRootWorkgroup={rootWorkgroupId === workgroup.id}
      deleteWorkgroup={deleteWorkgroup}
      canDeleteWorkgroup={canDeleteWorkgroup}
    />
  );
};

export const WorkgroupDetailsContainer =
  withRegisterBlade(bladeConfig)(ContainerComponent);

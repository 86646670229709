import { StreamListItem } from "models/streams";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ButtonItem, useGridExportEvents } from "react-tools";

import Add from "@material-ui/icons/Add";
import CloudDownload from "@material-ui/icons/CloudDownload";
import Refresh from "@material-ui/icons/Refresh";
import { Delete } from "@material-ui/icons";

export const useStreamListBladeButtons = (
  fetchStreams: () => void,
  createStream: () => void,
  confirmDelete: () => void,
  selected: StreamListItem[],
  loading: boolean,
  gridApi: any,
  canEdit: boolean
) => {
  const exportFunctions = useGridExportEvents();
  const [t] = useTranslation();

  const buttons = useMemo<ButtonItem[]>(() => {
    const items: ButtonItem[] = [
      {
        tooltip: "streams.refresh",
        icon: () => <Refresh />,
        onClick: fetchStreams,
        disabled: loading,
      },
      {
        tooltip: "streams.delete",
        icon: () => <Delete />,
        disabled: selected ? selected.length === 0 : true,
        onClick: confirmDelete,
      },
    ];
    if (canEdit) {
      items.push({
        tooltip: "streams.create",
        icon: () => <Add />,
        onClick: createStream,
      });
    }
    items.push({
      tooltip: "streams.export",
      icon: () => <CloudDownload />,
      onClick: () => {
        if (exportFunctions && exportFunctions.exportCsv) {
          exportFunctions.exportCsv(
            `${t("streams.exportFileName").toString()}.csv`
          );
        }
      },
    });
    return items;
  }, [selected, loading, gridApi]);

  return buttons;
};

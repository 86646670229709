import { currentWorkgroupKey, getCookieValue } from "../../ajax";
import { WorkgroupDetailsMode } from "./types";
import {
  TemplateLibrary,
  WorkgroupDetailsModel,
  Workgroup,
  DeleteWorkgroupCountInfo,
} from "models";
import { HttpService } from "react-tools";

export interface IWorkgroupService {
  getWorkgroups: (workgroupId: number) => Promise<Workgroup[]>;
  getWorkgroupTemplates: (workgroupId: number) => Promise<number[]>;
  getTemplates: () => Promise<TemplateLibrary[]>;
  saveWorkgroup: (
    workgroup: WorkgroupDetailsModel,
    mode: WorkgroupDetailsMode
  ) => Promise<any>;
  getWorkgroup: (workgroupId: number) => Promise<WorkgroupDetailsModel>;
  getDeleteWorkgroupInfo: (
    workgroupId: number
  ) => Promise<DeleteWorkgroupCountInfo>;
}

export class WorkgroupService implements IWorkgroupService {
  async getWorkgroups(
    workgroupId: number = Number(getCookieValue(currentWorkgroupKey))
  ): Promise<Workgroup[]> {
    if (
      (process.env.REACT_APP_HEADER_01 as string) ===
      "MVision-Current-WorkgroupId"
    ) {
      // this will run when the app is called in mvision-html scope
      return await HttpService.get<Workgroup[]>(
        `v6/admin/workgroup/${workgroupId}/tree`
      );
    } else {
      return await HttpService.get<Workgroup[]>(`v6/admin/workgroup/1/tree`);
    }
  }

  async getWorkgroupTemplates(
    workgroupId: number = Number(getCookieValue(currentWorkgroupKey))
  ): Promise<number[]> {
    return await HttpService.get<number[]>(
      `v6/admin/workgroup/templateLibraries/${workgroupId}`
    );
  }

  async getTemplates(): Promise<TemplateLibrary[]> {
    return await HttpService.get<TemplateLibrary[]>(
      `v6/admin/workgroup/templateLibraries`
    );
  }

  getWorkgroup = async (
    workgroupId: number
  ): Promise<WorkgroupDetailsModel> => {
    let response;

    if (
      (process.env.REACT_APP_HEADER_01 as string) ===
      "MVision-Current-WorkgroupId"
    ) {
      response = await HttpService.get<WorkgroupDetailsModel>(
        `v6/admin/workgroup/${workgroupId}`
      );
    } else {
      response = await HttpService.get<WorkgroupDetailsModel>(
        `v6/admin/workgroup/${workgroupId}`
      );
    }
    return response;
  };

  saveWorkgroup = async (
    workgroup: WorkgroupDetailsModel,
    mode: WorkgroupDetailsMode
  ): Promise<{ id: number }> => {
    let response;

    if (mode === WorkgroupDetailsMode.NEW) {
      response = await HttpService.post<any>(`v6/admin/workgroup`, workgroup);
    } else if (mode === WorkgroupDetailsMode.EDIT) {
      response = await HttpService.put<any>(`v6/admin/workgroup`, workgroup);
    }

    return { id: response.data };
  };

  getDeleteWorkgroupInfo = async (
    workgroupId: number
  ): Promise<DeleteWorkgroupCountInfo> => {
    return await HttpService.get<DeleteWorkgroupCountInfo>(
      `v6/admin/workgroup/${workgroupId}/deleteCountInfo`
    );
  };

  deleteWorkgroup = async (workgroupId: number): Promise<void> => {
    return await HttpService.delete(`v6/admin/workgroup/${workgroupId}`);
  };
}

import { NormalizedIdNames, Workgroup } from "models";
import { TreeItem } from "react-tools";

export const canDeleteWorkgroup = (
  item: TreeItem | undefined,
  treeItems: TreeItem[],
  workgroups: NormalizedIdNames<Workgroup>
): boolean => {
  if (!item || !workgroups) {
    return false;
  }

  const canDeleteCurrent = !workgroups[item.modelId]?.nonDeletable;

  if (item.hasChildren) {
    const children = treeItems.filter((e) => e.treeParentId === item.treeId);

    const canDeleteItemChildren = children.reduce(
      (acc: boolean, current: TreeItem) =>
        acc && canDeleteWorkgroup(current, treeItems, workgroups),
      true
    );

    return canDeleteCurrent && canDeleteItemChildren;
  }

  return canDeleteCurrent;
};

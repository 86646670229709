import { DeleteWorkgroupCountInfo } from "models";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { MathProblemConfirmDialog } from "react-tools";
import { WorkgroupService } from "workgroup/duck/service";

const service = new WorkgroupService();

export const useDeleteWorkgroupDialog = (
  workgroupName: string,
  workgroupId: number,
  onSave: () => void,
  onCancel?: () => void
) => {
  const [t] = useTranslation();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [loadingDeleteCountInfo, setLoadingDeleteCountInfo] = useState(false);
  const [deleteCountInfo, setDeleteCountInfo] =
    useState<DeleteWorkgroupCountInfo>();

  const deleteCallback = useCallback(() => {
    setDeleteDialogOpen(false);
    onSave();
  }, [onSave]);

  const confirmMessage = useMemo(
    () => t("workgroupDetails.deleteMessage", { workgroupName, interpolation: { escapeValue: true }}),
    [workgroupName, t]
  );

  const deleteExtraMessage = useMemo(
    () =>
      t("workgroupDetails.deleteCountMessage", {
        ...deleteCountInfo,
      } as DeleteWorkgroupCountInfo),
    [deleteCountInfo, t]
  );

  const closeDeleteConfirmationCallback = useCallback(() => {
    setDeleteDialogOpen(false);
    onCancel && onCancel();
  }, [setDeleteDialogOpen, onCancel]);

  const openDeleteWorkgroupDialog = useCallback(() => {
    setLoadingDeleteCountInfo(true);
    service
      .getDeleteWorkgroupInfo(workgroupId)
      .then((count: DeleteWorkgroupCountInfo) => {
        setDeleteCountInfo({
          ...count,
          workgroupCount: count.workgroupCount - 1,
        });
        setDeleteDialogOpen(true);
        setLoadingDeleteCountInfo(false);
      })
      .catch(() => setLoadingDeleteCountInfo(false));
  }, [workgroupId]);

  const DeleteWorkgroupDialog = useMemo(
    () => (
      <MathProblemConfirmDialog
        title={t<string>("workgroupDetails.deleteDialogTitle")}
        open={deleteDialogOpen}
        onSave={deleteCallback}
        message={confirmMessage}
        countCheck={undefined}
        warning={t<string>("workgroupDetails.deleteWarning")}
        checkboxText={t<string>("workgroupDetails.checkboxText")}
        extraMessage={deleteExtraMessage}
        onCancel={closeDeleteConfirmationCallback}
      />
    ),
    [
      deleteDialogOpen,
      deleteCallback,
      confirmMessage,
      deleteExtraMessage,
      closeDeleteConfirmationCallback,
      t,
    ]
  );

  return {
    DeleteWorkgroupDialog,
    loadingDeleteCountInfo,
    openDeleteWorkgroupDialog,
  };
};

import { ChannelListItem } from "models";
import { Reducer } from "redux";
import { ActionType } from "typesafe-actions";

import * as Actions from "./actions";
import { ChannelService } from "./service";
import { ActionTypes, ChannelState } from "./types";

type ChannelActions = ActionType<typeof Actions>;

const initialState: ChannelState = {
  channels: [],

  isFetching: false,

  details: {
    isFetching: false,
    isSaving: false,
    errorStatusCode: 0,
    model: null,
  },

  preselected: [],
};

export const channelReducer: Reducer<ChannelState, ChannelActions> = (
  state: ChannelState = initialState,
  action: ChannelActions
): ChannelState => {
  switch (action.type) {
    case ActionTypes.FETCH_CHANNELS_START:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypes.FETCH_CHANNELS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        channels: action.payload.channels,
      };
    }

    case ActionTypes.FETCH_CHANNELS_FAIL: {
      return {
        ...state,
        isFetching: false,
      };
    }

    case ActionTypes.FETCH_CHANNEL_START: {
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: true,
        },
      };
    }

    case ActionTypes.FETCH_CHANNEL_SUCCESS: {
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: false,
          errorStatusCode: 0,
          model: action.payload.channel,
        },
      };
    }

    case ActionTypes.FETCH_CHANNEL_FAIL: {
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: false,
        },
      };
    }

    case ActionTypes.SAVE_CHANNEL_START:
      return {
        ...state,
        details: {
          ...state.details,
          errorStatusCode: 0,
          isSaving: true,
        },
      };

    case ActionTypes.SAVE_CHANNEL_SUCCESS: {
      const channels: ChannelListItem[] = [...state.channels];
      const newItem = new ChannelService().formModelToListItem(
        action.payload.channel
      );
      if (action.payload.create) {
        channels._insert(newItem, 0);
      } else {
        const index = channels.findIndex((l) => l.id === newItem.id);
        channels.splice(index, 1, newItem);
      }
      return {
        ...state,
        channels,
        details: {
          ...state.details,
          model: action.payload.channel,
          isSaving: false,
        },
      };
    }

    case ActionTypes.DELETE_CHANNEL_START: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case ActionTypes.DELETE_CHANNEL_FAIL: {
      return {
        ...state,
        isFetching: false,
      };
    }

    case ActionTypes.DELETE_CHANNEL_SUCCESS: {
      const channels = [...state.channels];
      const index = channels.findIndex(
        (e) => e.id === action.payload.channel.id
      );
      channels.splice(index, 1);
      return {
        ...state,
        channels,
        isFetching: false,
      };
    }

    case ActionTypes.SET_PRESELECTED: {
      return {
        ...state,
        preselected: action.payload.channels,
      };
    }

    case ActionTypes.SAVE_CHANNEL_FAIL: {
      return {
        ...state,
        details: {
          ...state.details,
          errorStatusCode: action.payload.status,
          isSaving: false,
        },
      };
    }

    case ActionTypes.CLEAR_CHANNEL_DETAILS: {
      return {
        ...state,
        details: {
          ...state.details,
          model: null,
        },
      };
    }

    default:
      return state;
  }
};

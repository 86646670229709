import { FastField, FastFieldProps, Form, Formik } from 'formik';
import { StreamAudioOutput, StreamFormModel } from 'models/streams';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import FadeIn from 'react-fade-in';
import { useTranslation } from 'react-i18next';
import {
  BladeProps,
  ButtonItem,
  Footer,
  FooterSubmit,
  FormActionsCard,
  FormCard,
  IdName,
  useBladeButtons,
  useBladeClosing,
} from 'react-tools';
import { enumToMenuItems } from 'utils/enum';

import {
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField,
} from '@material-ui/core';
import Refresh from '@material-ui/icons/Refresh';

import { StreamFormListLoader } from './loader';
import { StreamFormActions } from './stream-form.actions';
import { useStyles } from './stream-form.jss';
import { StreamValidationScheme } from './validation.scheme';

const getEmptyStream = (device: IdName): StreamFormModel => ({
  id: 0,
  name: '',
  rowVersion: '',
  channelId: 0,
  channelName: '',
  hardwareId: device.id,
  hardwareName: device.name,
  x: 0,
  y: 0,
  width: 100,
  height: 100,
  audioOutputType: StreamAudioOutput.All,
  hardwareSerialNumber: '',
});

interface StreamFormProps {
  /**
   * Id of the stream. If undefined then this is a new player, not saved yet.
   */
  workgroup: IdName;
  streamId?: number;
  channel: IdName;
  device: IdName;
  stream: StreamFormModel | null;
  isSaving: boolean;
  isFetching: boolean;
  savingErrorCode: number;
  fetchingFirstChannel: boolean;
}

interface StreamFormActions {
  fetchStream: (streamId: number) => void;
  openChannelSelectionBlade: (
    workgroupId: number,
    selectedChannel: IdName
  ) => void;
  saveStream: (stream: StreamFormModel) => void;
  fetchFirstChannel: (workgroupId: number) => void;
  closeBlade: () => void;
}

export const StreamForm = (
  props: StreamFormActions & StreamFormProps & BladeProps
) => {
  const classes = useStyles();

  const [stream, setStream] = useState(
    props.stream ? props.stream : getEmptyStream(props.device)
  );
  const [t] = useTranslation();

  const buttons: ButtonItem[] = useMemo<ButtonItem[]>(
    () => [
      {
        disabled: props.savingErrorCode !== 409,
        icon: () => <Refresh />,
        onClick: () => {
          if (props.stream) props.fetchStream(props.stream.id);
        },
        tooltip: t('streamForm.refresh'),
      },
    ],
    [props.savingErrorCode, props.stream]
  );

  useBladeButtons(buttons, [props.savingErrorCode, props.device]);
  useBladeClosing(
    props.bladeId,
    () => !props.isDirty,
    () => {}
  );

  useEffect(() => {
    if (props.streamId) {
      props.fetchStream(props.streamId);
    } else {
      setStream(getEmptyStream(props.device));
      props.fetchFirstChannel(props.workgroup.id);
    }
  }, [props.streamId, props.workgroup?.id]);

  useEffect(() => {
    if (props.stream) {
      setStream(props.stream);
    }
  }, [props.stream]);

  const submitForm = useCallback(
    (values: StreamFormModel) => {
      if (!props.channel?.id) {
        return;
      }
      props.saveStream({
        ...values,
        channelId: props.channel.id,
        channelName: props.channel.name,
      });
      props.setDirty(false);
    },
    [
      props.setDirty,
      props.saveStream,
      props.device,
      props.channel.id,
      props.channel.name,
    ]
  );

  const onFormChanged = useCallback(
    (dirty: boolean) => () => props.setDirty(dirty),
    [props.setDirty]
  );

  const selectChannel = useCallback(() => {
    return props.openChannelSelectionBlade(props.workgroup.id, props.channel);
  }, [props.workgroup.id, props.stream, props.channel]);

  if (props.isFetching) {
    return <StreamFormListLoader />;
  }

  return (
    <div className={classes.container}>
      <FadeIn className={classes.fadeIn}>
        <Formik
          initialValues={stream}
          enableReinitialize={true}
          onSubmit={submitForm}
          validationSchema={StreamValidationScheme}
          validateOnChange
        >
          {({ submitForm, values, handleChange, dirty }) => (
            <Form onBlur={onFormChanged(dirty)} className={classes.form}>
              <div className={classes.formContent}>
                <FormCard>
                  <Grid
                    container
                    spacing={2}
                    classes={{ root: classes.panelContainer }}
                  >
                    {values.id > 0 && (
                      <Grid item>
                        <TextField
                          label={t('channels.id')}
                          variant='filled'
                          fullWidth
                          disabled
                          InputProps={{
                            disableUnderline: true,
                          }}
                          value={values.id}
                        />
                      </Grid>
                    )}

                    <Grid item>
                      <FastField name='name'>
                        {({ field, form }: FastFieldProps<StreamFormModel>) => (
                          <TextField
                            label={t('streamForm.name')}
                            variant='filled'
                            fullWidth
                            error={form.errors.name ? true : false}
                            helperText={form.errors.name}
                            InputProps={{ disableUnderline: true }}
                            inputProps={{ maxLength: 50 }}
                            {...field}
                            autoFocus={stream.id === 0}
                          />
                        )}
                      </FastField>
                    </Grid>

                    <Grid item>
                      <div className={classes.inlineFieldsContainer}>
                        <FastField name='x'>
                          {({
                            field,
                            form,
                          }: FastFieldProps<StreamFormModel>) => (
                            <TextField
                              label={t('streamForm.x')}
                              variant='filled'
                              type='number'
                              error={form.errors.x ? true : false}
                              helperText={form.errors.x}
                              InputProps={{ disableUnderline: true }}
                              inputProps={{ max: 100, min: 0 }}
                              {...field}
                              className={classes.inlineField}
                            />
                          )}
                        </FastField>
                        <FastField name='y'>
                          {({
                            field,
                            form,
                          }: FastFieldProps<StreamFormModel>) => (
                            <TextField
                              label={t('streamForm.y')}
                              variant='filled'
                              type='number'
                              error={form.errors.y ? true : false}
                              helperText={form.errors.y}
                              InputProps={{ disableUnderline: true }}
                              inputProps={{ max: 100 }}
                              {...field}
                              className={classes.inlineField}
                            />
                          )}
                        </FastField>
                        <FastField name='width'>
                          {({
                            field,
                            form,
                          }: FastFieldProps<StreamFormModel>) => (
                            <TextField
                              label={t('streamForm.width')}
                              variant='filled'
                              type='number'
                              error={form.errors.width ? true : false}
                              helperText={form.errors.width}
                              InputProps={{ disableUnderline: true }}
                              inputProps={{ max: 100 }}
                              {...field}
                              className={classes.inlineField}
                            />
                          )}
                        </FastField>
                        <FastField name='height'>
                          {({
                            field,
                            form,
                          }: FastFieldProps<StreamFormModel>) => (
                            <TextField
                              label={t('streamForm.height')}
                              variant='filled'
                              type='number'
                              error={form.errors.height ? true : false}
                              helperText={form.errors.height}
                              InputProps={{ disableUnderline: true }}
                              inputProps={{ max: 100 }}
                              {...field}
                              className={classes.inlineField}
                            />
                          )}
                        </FastField>
                      </div>
                    </Grid>

                    <Grid item>
                      <FastField name='audioOutputType'>
                        {({ field }: FastFieldProps<StreamFormModel>) => (
                          <FormControl
                            variant='filled'
                            fullWidth
                            style={{ width: '100%' }}
                          >
                            <InputLabel>
                              {t('streams.audioOutputType')}
                            </InputLabel>
                            <Select
                              value={values.audioOutputType}
                              onChange={handleChange}
                              name='audioOutputType'
                              disableUnderline
                            >
                              {enumToMenuItems(
                                StreamAudioOutput,
                                'streams.audioOutputType'
                              )}
                            </Select>
                          </FormControl>
                        )}
                      </FastField>
                    </Grid>
                  </Grid>
                </FormCard>

                <FormActionsCard marginTop padding={-2}>
                  <StreamFormActions
                    selectChannel={selectChannel}
                    selectedChannelName={props.channel.name}
                    loadingChannel={props.fetchingFirstChannel}
                  />
                </FormActionsCard>
              </div>

              <Footer>
                <FooterSubmit
                  hasCancelButton={true}
                  cancelButtonLabel={t('cancel')}
                  cancel={props.closeBlade}
                  submitButtonLabel={props.stream ? t('save') : t('create')}
                  submitInProgress={props.isSaving}
                  submit={submitForm}
                />
              </Footer>
            </Form>
          )}
        </Formik>
      </FadeIn>
    </div>
  );
};

import { actions, selectors, thunks } from "device/duck";
import i18n from "localization/i18n";
import { DeviceListItem } from "models/devices";
import React, { useCallback } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import {
  BladeActions,
  BladeConfig,
  BladeProps,
  DataGridContextProvider,
  IdName,
  useBladeClosing,
  withRegisterBlade,
} from "react-tools";

import { Devices } from "./devices";

export const DevicesBladeName = "DEVICES";

interface DevicesContainerProps extends BladeProps {
  workgroup: IdName;
  location?: IdName;
}

const bladeConfig: BladeConfig<DevicesContainerProps> = {
  bladeType: DevicesBladeName,
  size: { defaultWidth: 800, minWidth: 400 },
  title: () => i18n.t("devices.title"),
  allowMultipleInstances: false,
};

const ContainerComponent = ({
  workgroup,
  location,
  bladeId,
}: DevicesContainerProps & BladeProps) => {
  const dispatch = useDispatch();

  const items = useSelector(selectors.selectDevices);
  const fetchingDevices = useSelector(selectors.selectIsFetchingDevices);
  const preselected = useSelector(selectors.selectPreselected);

  const fetchDevices = useCallback(
    (workgroup, location) =>
      dispatch(thunks.fetchDevicesAsync(workgroup, location)),
    [workgroup, location]
  );

  const createDevice = useCallback(
    () =>
      batch(() => {
        dispatch(
          BladeActions.closeChildrenBlades(bladeId, [
            actions.clearDeviceDetails(),
            actions.openDeviceForm(bladeId, {
              deviceName: "",
              deviceId: 0,
              workgroup,
              location,
            }),
          ])
        );
      }),
    [workgroup.id, location?.id]
  );

  const editDevice = useCallback(
    (device: DeviceListItem) =>
      batch(() => {
        dispatch(actions.setPreselected([device]));
        dispatch(
          BladeActions.closeChildrenBlades(
            bladeId,
            actions.openDeviceForm(bladeId, {
              deviceName: device.name,
              deviceId: device.id,
              workgroup,
              location,
            })
          )
        );
      }),
    [workgroup.id, location?.id]
  );

  const deleteDevices = useCallback(
    (devices: DeviceListItem[]) =>
      batch(() => {
        dispatch(BladeActions.forceCloseChildrenBlades(bladeId));
        dispatch(thunks.deleteDevicesAsync(devices));
      }),
    [workgroup.id, location?.id]
  );

  const clearPreselected = useCallback(
    () => dispatch(actions.setPreselected([])),
    [dispatch]
  );

  useBladeClosing(
    bladeId,
    () => true,
    () => {
      dispatch(actions.clearDeviceDetails());
      dispatch(actions.setPreselected([]));
    }
  );

  return (
    <DataGridContextProvider>
      <Devices
        bladeId={bladeId}
        clearPreselected={clearPreselected}
        preselected={preselected}
        workgroup={workgroup}
        location={location}
        items={items}
        fetchDevices={fetchDevices}
        fetchingDevices={fetchingDevices}
        createDevice={createDevice}
        editDevice={editDevice}
        deleteDevices={deleteDevices}
      />
    </DataGridContextProvider>
  );
};

export const DevicesContainer =
  withRegisterBlade(bladeConfig)(ContainerComponent);

import { actions } from "../duck";

import React, { useCallback } from "react";
import { TreeActions, TreeSelectors } from "react-tools";

import { useDispatch, useSelector, batch } from "react-redux";
import { WorkgroupTree } from "./workgroup-tree";

import { WORKGROUP_TREE_NAME } from "../../constants";

import {
  useOpenWorkgroupDetails,
  useOpenCreateWorkgroup,
  useWorkgroupTreeActions,
  useDetailsBladeOpen,
} from "./hooks";
import * as selectors from "workgroup/duck/selectors";

export const WorkgroupTreeContainer = () => {
  const dispatch = useDispatch();

  const openWorkgroupDetailsBlade = useOpenWorkgroupDetails();
  const openNewWorkgroupBlade = useOpenCreateWorkgroup();

  const {
    isOpeningDetails,
    shouldUnselectAll,
    resetIsDetailsOpen,
    setIsDetailsOpen,
  } = useDetailsBladeOpen();

  const {
    collapseAll,
    expandAll,
    fetchWorkgroups,
    searchWorkgroups,
    toggleFavorite,
  } = useWorkgroupTreeActions();

  const onDetailsClosed = useCallback(() => {
    if (shouldUnselectAll()) {
      dispatch(TreeActions.unselectAll(WORKGROUP_TREE_NAME));
    }

    dispatch(actions.clearWorkgroupDetails());
    resetIsDetailsOpen();
  }, []);

  const openWorkgroupDetails = useCallback((workgroupId, workgroupName) => {
    isOpeningDetails.current = true;

    openWorkgroupDetailsBlade(workgroupId, workgroupName, onDetailsClosed);
    setIsDetailsOpen();
  }, []);

  const openWorkgroupNew = useCallback((parentWorkgroupId) => {
    batch(() => {
      isOpeningDetails.current = true;

      openNewWorkgroupBlade(parentWorkgroupId, onDetailsClosed);
      setIsDetailsOpen();
    });
  }, []);

  const favoriteWorkgroups = useSelector(selectors.selectFavoriteWorkgroups);
  const workgroupsFetching = useSelector(selectors.selectIsFetchingWorkgroups);
  const workgroups = useSelector(selectors.selectWorkgroups);
  const isFetchingWorkgroup = useSelector(selectors.selectIsFetchingWorkgroup);
  const isDeletingWorkgroup = useSelector(selectors.selectIsDeletingWorkgroup);
  const treeActionState = useSelector((state: any) =>
    TreeSelectors.selectActionState(state, WORKGROUP_TREE_NAME)
  );

  return (
    <WorkgroupTree
      fetchWorkgroups={fetchWorkgroups}
      collapseAll={collapseAll}
      expandAll={expandAll}
      searchWorkgroups={searchWorkgroups}
      toggleFavorite={toggleFavorite}
      openWorkgroupDetails={openWorkgroupDetails}
      openWorkgroupNew={openWorkgroupNew}
      favoriteWorkgroups={favoriteWorkgroups}
      workgroups={workgroups}
      treeActionState={treeActionState}
      treeName={WORKGROUP_TREE_NAME}
      workgroupsFetching={workgroupsFetching}
      isFetchingWorkgroup={isFetchingWorkgroup}
      isDeletingWorkgroup={isDeletingWorkgroup}
    />
  );
};

import {
  LocationFormBladeName,
  LocationFormContainerProps,
} from "location/form/location-form.container";
import { WorkgroupLocation } from "models";
import { openBlade, ErrorResponse } from "react-tools";
import { action } from "typesafe-actions";

import { ActionTypes } from "./types";

export const fetchLocationsStart = (workgroupId: number) =>
  action(ActionTypes.FETCH_LOCATIONS_START, { workgroupId });
export const fetchLocationsSuccess = (locations: WorkgroupLocation[]) =>
  action(ActionTypes.FETCH_LOCATIONS_SUCCESS, { locations });
export const fetchLocationsFail = (error: any) =>
  action(ActionTypes.FETCH_LOCATIONS_FAIL, { error });

export const fetchLocationStart = (locationId: number) =>
  action(ActionTypes.FETCH_LOCATION_START, { locationId });
export const fetchLocationSuccess = (location: WorkgroupLocation) =>
  action(ActionTypes.FETCH_LOCATION_SUCCESS, { location });
export const fetchLocationFail = (error: any) =>
  action(ActionTypes.FETCH_LOCATION_FAIL, { error });

export const saveLocationStart = (location: WorkgroupLocation) =>
  action(ActionTypes.SAVE_LOCATION_START, { location });
export const saveLocationSuccess = (
  location: WorkgroupLocation,
  create: boolean,
  sameWorkgroup: boolean
) =>
  action(ActionTypes.SAVE_LOCATION_SUCCESS, {
    location,
    create,
    sameWorkgroup,
  });
export const saveLocationFail = ({ message, status }: ErrorResponse) =>
  action(ActionTypes.SAVE_LOCATION_FAIL, { message, status });

export const clearLocationDetails = () =>
  action(ActionTypes.CLEAR_LOCATION_DETAILS);

export const setPreselected = (locations: WorkgroupLocation[]) =>
  action(ActionTypes.SET_PRESELECTED, { locations });

export const openLocationFormBlade = (
  parentBladeId: string,
  props: LocationFormContainerProps
) =>
  openBlade<LocationFormContainerProps>(
    parentBladeId,
    LocationFormBladeName,
    props
  );

export const deleteLocationsStart = (locations: WorkgroupLocation[]) =>
  action(ActionTypes.DELETE_LOCATIONS_START, { locations });

export const deleteLocationsSuccess = (locations: WorkgroupLocation[]) =>
  action(ActionTypes.DELETE_LOCATIONS_SUCCESS, { locations });

export const deleteLocationsFail = ({ message, status }: ErrorResponse) =>
  action(ActionTypes.DELETE_LOCATIONS_FAIL, { message, status });

import { Button, CircularProgress, Typography } from "@material-ui/core";
import { Formik } from "formik";
import {
  DeleteWorkgroupCountInfo,
  TemplateLibrary,
  WorkgroupDetailsModel,
} from "models";
import React, { ChangeEvent, useEffect, useCallback, useMemo } from "react";
import FadeIn from "react-fade-in";
import { useTranslation } from "react-i18next";
import {
  BladeProps,
  Footer,
  FooterSubmit,
  FormActionsCard,
  IdName,
  useBladeClosing,
} from "react-tools";
import { WorkgroupDetailsMode } from "workgroup/duck/types";
import { useDeleteWorkgroupDialog } from "./delete-workgroup-dialog";

import { useWorkgroupDirty } from "./hooks";
import { WorkgroupDetailsContentLoader } from "./loader";
import { WorkgroupValidationSchema } from "./validation.schema";
import { WorkgroupActions } from "./workgroup-details-actions";
import { WorkgroupDetailsFields } from "./workgroup-details-fields";
import { useStyles } from "./workgroup-details.jss";

export interface WorkgroupDetailsProps {
  mode: WorkgroupDetailsMode;
  model: WorkgroupDetailsModel;
  workgroupId: number;
  parentWorkgroupId: number;
  isFetchingWorkgroup: boolean;
  isDeletingWorkgroup: boolean;
  isSaving: boolean;
  saved: boolean;
  workgroupTemplates: TemplateLibrary[];
  isRootWorkgroup: boolean;
  canDeleteWorkgroup: any;
}

export interface WorkgroupDetailsActions {
  saveWorkgroup: (
    workgroup: WorkgroupDetailsModel,
    mode: WorkgroupDetailsMode
  ) => void;
  getWorkgroup: (workgroupId: number) => void;
  openWorkgroupLocations: (workgroup: IdName) => void;
  openWorkgroupDevices: (workgroup: IdName) => void;
  openWorkgroupChannels: (workgroup: IdName) => void;
  openDeviceSettings: (workgroup: IdName) => void;
  openTemplateLibrarySelection: () => void;
  setTitle: (bladeId: string, title: string) => void;
  closeBlade: () => void;
  onClose: () => void;
  deleteWorkgroup: (workgroupId: number) => void;
}

export const getEmptyCompany = () => {
  return {
    companyName: "",
    companyNumber: "",
    companyCountry: "",
    companyAddress: "",
    companyCity: "",
    companyState: "",
    companyZip: "",
    companyFirstName: "",
    companyLastName: "",
    companyPhone: "",
    companyEmail: "",
    templates: [],
  };
};

type Props = WorkgroupDetailsProps & WorkgroupDetailsActions & BladeProps;

export const WorkgroupDetails: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const [t] = useTranslation();

  const formDirtyHandler = useWorkgroupDirty();

  console.log(props.canDeleteWorkgroup);

  useEffect(() => {
    if (props.mode === WorkgroupDetailsMode.EDIT) {
      props.getWorkgroup(props.workgroupId);
    }
  }, [props.mode, props.workgroupId]);

  useEffect(() => {
    if (props.model && props.workgroupTemplates) {
      if (props.model.id) {
        const templatesDirty = !props.model.templates._equals(
          props.workgroupTemplates,
          "id"
        );
        formDirtyHandler.setTemplatesDirty(templatesDirty);
      } else {
        if (props.workgroupTemplates.length) {
          formDirtyHandler.setTemplatesDirty(true);
        } else {
          formDirtyHandler.setTemplatesDirty(false);
        }
      }
    }
  }, [props.workgroupTemplates, props.model]);

  useEffect(() => {
    if (props.isDirty !== formDirtyHandler.isDirty) {
      props.setDirty(formDirtyHandler.isDirty);
    }
  }, [formDirtyHandler.isDirty]);

  useEffect(() => {
    if (props.saved === true) {
      formDirtyHandler.clearDirty();
    }
  }, [props.saved]);

  useEffect(() => {
    if (props.model.name) {
      props.setTitle(props.bladeId, props.model.name);
    }
  }, [props.bladeId, props.model.name]);

  useBladeClosing(
    props.bladeId,
    () => {
      return !props.isDirty;
    },
    () => {
      props.onClose();
    }
  );

  const openWorkgroupLocations = useCallback(() => {
    props.openWorkgroupLocations({
      id: props.model.id,
      name: props.model.name,
    });
  }, [props.model.id, props.model.name]);

  const openWorkgroupDevices = useCallback(() => {
    props.openWorkgroupDevices({
      id: props.model.id,
      name: props.model.name,
    });
  }, [props.model.id]);

  const openWorkgroupChannels = useCallback(() => {
    props.openWorkgroupChannels({
      id: props.model.id,
      name: props.model.name,
    });
  }, [props.model.id]);

  const openDeviceSettings = useCallback(() => {
    props.openDeviceSettings({
      id: props.model.id,
      name: props.model.name,
    });
  }, [props.model.id]);

  const onSubmit = useCallback(
    (workgroup: WorkgroupDetailsModel) =>
      props.saveWorkgroup(workgroup, props.mode),
    [props.mode, props.workgroupTemplates]
  );

  const deleteWorkgroupCallback = useCallback(
    () => props.deleteWorkgroup(props.workgroupId),
    [props.deleteWorkgroup, props.workgroupId]
  );

  const {
    DeleteWorkgroupDialog,
    loadingDeleteCountInfo,
    openDeleteWorkgroupDialog,
  } = useDeleteWorkgroupDialog(
    props.model.name,
    props.workgroupId,
    deleteWorkgroupCallback
  );

  if (props.isDeletingWorkgroup || props.isFetchingWorkgroup) {
    return <WorkgroupDetailsContentLoader />;
  }

  return (
    <div className={classes.container}>
      <FadeIn className={classes.fadeIn}>
        <Formik
          initialValues={props.model}
          enableReinitialize={true}
          onSubmit={onSubmit}
          validationSchema={WorkgroupValidationSchema}
          validateOnChange
        >
          {({
            values,
            handleChange,
            setValues,
            submitForm,
            dirty,
            errors,
            setFieldValue,
          }) => (
            <>
              <div className={classes.form}>
                <WorkgroupDetailsFields
                  errors={errors}
                  workgroup={values}
                  setWorkgroup={setValues}
                  onWorkgroupChange={(event: ChangeEvent<HTMLInputElement>) => {
                    if (event.target.name === "name") {
                      setFieldValue("companyName", event.target.value);
                    }

                    handleChange(event);
                  }}
                  workgroupTemplates={props.workgroupTemplates}
                  openTemplateLibrarySelection={
                    props.openTemplateLibrarySelection
                  }
                  dirty={dirty}
                  setDirty={formDirtyHandler.setFieldsDirty}
                />
                {props.mode === WorkgroupDetailsMode.EDIT && (
                  <>
                    <FormActionsCard marginTop padding={-2}>
                      <WorkgroupActions
                        openWorkgroupLocations={openWorkgroupLocations}
                        openWorkgroupDevices={openWorkgroupDevices}
                        openDeviceSettings={openDeviceSettings}
                        openWorkgroupChannels={openWorkgroupChannels}
                      />
                    </FormActionsCard>

                    {!props.isRootWorkgroup && (
                      <FormActionsCard marginTop padding={1}>
                        <div className={classes.deleteContainer}>
                          <Typography variant='body1' color='error'>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: props.canDeleteWorkgroup
                                  ? t("workgroupDetails.deleteSectionMessage")
                                  : t(
                                      "workgroupDetails.workgroupIsNonDeletable"
                                    ),
                              }}
                            ></span>
                          </Typography>

                          <br />
                          <Button
                            onClick={openDeleteWorkgroupDialog}
                            disabled={!props.canDeleteWorkgroup}
                            variant='outlined'
                          >
                            {t("workgroupDetails.deleteWorkgroupButtonText")}{" "}
                            &nbsp;
                            {loadingDeleteCountInfo && (
                              <CircularProgress size={12} />
                            )}
                          </Button>
                        </div>
                      </FormActionsCard>
                    )}
                  </>
                )}
              </div>
              <Footer>
                <FooterSubmit
                  hasCancelButton={true}
                  cancelButtonLabel={t("workgroupDetails.footer.cancel")}
                  cancel={props.closeBlade}
                  submitButtonLabel={
                    props.mode === WorkgroupDetailsMode.NEW
                      ? t("workgroupDetails.footer.create")
                      : t("workgroupDetails.footer.save")
                  }
                  submitInProgress={props.isSaving}
                  submit={submitForm}
                />
              </Footer>
            </>
          )}
        </Formik>
      </FadeIn>

      {DeleteWorkgroupDialog}
    </div>
  );
};

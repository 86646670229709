import { CountFooter } from "components/ExternalFilterFooter";
import { StreamListItem } from "models/streams";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ColumnSettings,
  DataGrid,
  DataGridHeader,
  DataGridSelectionType,
  Footer,
  GridCard,
  IdName,
  useBladeButtons,
  useBladeClosing,
  useGridApi,
} from "react-tools";

import { CircularProgress } from "@material-ui/core";

import { useStreamListBladeButtons } from "./buttons.hook";
import { streamsColumnSettings } from "./column.settings";
import { useStyles } from "./streams.jss";
import { ConfirmationDialog } from "components/ConfirmationDialog";

export interface StreamsProps {
  bladeId: string;
  items: Array<StreamListItem>;
  channel?: IdName;
  device?: IdName;
  fetchingStreams: boolean;
  preselected: StreamListItem[];
}

export interface StreamsActions {
  fetchStreams: (channel?: IdName, device?: IdName) => void;
  createStream: () => void;
  editStream: (stream: StreamListItem) => void;
  deleteStreams: (streams: StreamListItem[]) => void;
  clearPreselected: () => void;
}

type Props = StreamsProps & StreamsActions;

export const Streams: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  const columns = useMemo<ColumnSettings[]>(
    () => streamsColumnSettings(props.channel !== undefined),
    [props.channel]
  );
  const gridApi = useGridApi();
  const [t] = useTranslation();

  const [selected, setSelected] = useState<StreamListItem[]>([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const fetchStreamsCallback = useCallback(() => {
    props.fetchStreams(props.channel, props.device);
    props.clearPreselected();
    setSelected([]);
  }, [props.fetchStreams, props.channel, props.device]);

  const confirmDelete = useCallback(() => {
    setDeleteDialogOpen(true);
  }, [setDeleteDialogOpen]);

  const deleteCallback = useCallback(() => {
    props.deleteStreams(selected);
    setDeleteDialogOpen(false);
    setSelected([]);
  }, [selected, props.deleteStreams]);

  const closeDeleteConfirmationCallback = useCallback(() => {
    setDeleteDialogOpen(false);
  }, [setDeleteDialogOpen]);

  const buttons = useStreamListBladeButtons(
    fetchStreamsCallback,
    props.createStream,
    confirmDelete,
    selected,
    props.fetchingStreams,
    gridApi,
    props.device !== undefined
  );

  useBladeButtons(buttons, [selected, props.fetchingStreams]);

  useBladeClosing(
    props.bladeId,
    () => true,
    () => {}
  );

  useEffect(() => {
    props.fetchStreams(props.channel, props.device);
  }, [props.channel, props.device]);

  const onRowClicked = useCallback(
    (stream: StreamListItem) => {
      setSelected([stream]);
      props.editStream(stream);
    },
    [props.editStream]
  );

  const message = useMemo(() => {
    if (selected && Array.isArray(selected)) {
      if (selected.length === 1) {
        return t<string>("streams.deleteSingle", {
          name: selected[0].name,
          interpolation: { escapeValue: true }
        });
      }

      if (selected.length > 1) {
        return t<string>("streams.deleteMultiple", {
          count: selected ? selected.length : 0,
        });
      }
    }

    return "";
  }, [selected]);

  return props.fetchingStreams ? (
    <div className={classes.loadingContainer}>
      <div className={classes.loading}>
        <CircularProgress color='inherit' size={60} />
      </div>
    </div>
  ) : (
    <div className={classes.container}>
      <GridCard>
        <DataGridHeader loading={props.fetchingStreams} />
        <DataGrid
          onSelect={setSelected}
          onRowClicked={onRowClicked}
          identifier={"streams"}
          noDataMessage={"streams.noResults"}
          defaultSelection={props.preselected}
          selectionType={DataGridSelectionType.Multiple}
          idColumn={"id"}
          items={props.items}
          columnSettings={columns}
        />
      </GridCard>
      <ConfirmationDialog
        title={t<string>("streams.delete")}
        open={deleteDialogOpen}
        content={message}
        onConfirm={deleteCallback}
        onCancel={closeDeleteConfirmationCallback}
      />
      <Footer>
        <CountFooter />
      </Footer>
    </div>
  );
};

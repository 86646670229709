import { WorkgroupLocation } from "models";

export const areaName = "location";

export interface LocationState {
  locations: WorkgroupLocation[];
  isFetching: boolean;

  details: {
    isSaving: boolean;
    isFetching: boolean;
    errorStatusCode: number;
    model: WorkgroupLocation | null;
  };

  preselected: WorkgroupLocation[];
}

export enum ActionTypes {
  FETCH_LOCATIONS_START = "FETCH_LOCATIONS_START",
  FETCH_LOCATIONS_SUCCESS = "FETCH_LOCATIONS_SUCCESS",
  FETCH_LOCATIONS_FAIL = "FETCH_LOCATIONS_FAIL",

  FETCH_LOCATION_START = "FETCH_LOCATION_START",
  FETCH_LOCATION_SUCCESS = "FETCH_LOCATION_SUCCESS",
  FETCH_LOCATION_FAIL = "FETCH_LOCATION_FAIL",

  CLEAR_LOCATION_DETAILS = "CLEAR_LOCATION_DETAILS",

  SAVE_LOCATION_START = "SAVE_LOCATION_START",
  SAVE_LOCATION_SUCCESS = "SAVE_LOCATION_SUCCESS",
  SAVE_LOCATION_FAIL = "SAVE_LOCATION_FAIL",

  DELETE_LOCATIONS_START = "DELETE_LOCATIONS_START",
  DELETE_LOCATIONS_SUCCESS = "DELETE_LOCATIONS_SUCCESS",
  DELETE_LOCATIONS_FAIL = "DELETE_LOCATIONS_FAIL",

  SET_PRESELECTED = "SET_PRESELECTED",
}

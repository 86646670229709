import { selectDeviceById } from "device/duck/selectors";
import i18n from "localization/i18n";
import { ChannelType, ThunkTypeAction, ThunkTypeDispatch } from "models";
import { StreamFormModel, StreamListItem } from "models/streams";
import { batch } from "react-redux";
import {
  BadRequestError,
  ConcurrencyError,
  ErrorResponse,
  IdName,
  pushNotification,
  updateBladeProps,
} from "react-tools";
import { AppState } from "store";

import { StreamFormBladeName } from "../form/stream-form.container";
import { StreamService } from "./service";

import * as actions from "./actions";

const service = new StreamService();

export const fetchStreamsAsync = (
  channel?: IdName,
  device?: IdName
): ThunkTypeAction<void> => {
  return async (dispatch: ThunkTypeDispatch) => {
    if (!channel && !device)
      throw "Cannot fetch streams without either a channel or a device";

    dispatch(actions.fetchStreamsStart(channel?.id, device?.id));

    try {
      const streams: StreamListItem[] = await service.getStreams(
        channel?.id,
        device?.id
      );
      dispatch(actions.fetchStreamsSuccess(streams));
    } catch (err) {
      dispatch(actions.fetchStreamsFail(err));
    }
  };
};

export const fetchStreamAsync = (streamId: number): ThunkTypeAction<void> => {
  return async (dispatch: ThunkTypeDispatch, getState: () => AppState) => {
    dispatch(actions.fetchStreamStart());

    try {
      const stream: StreamFormModel = await service.getStream(streamId);
      dispatch(actions.fetchStreamSuccess(stream));
    } catch (err) {
      dispatch(actions.fetchStreamFail(err));
    }
  };
};

export const fetchFirstChannelAsync = (
  workgroupId: number,
  channelType: ChannelType
): ThunkTypeAction<void> => {
  return async (dispatch: ThunkTypeDispatch) => {
    dispatch(actions.firstChannelStart(workgroupId));
    try {
      const channel = await service.getFirstChannelInWorkgroup(
        workgroupId,
        channelType
      );
      dispatch(actions.firstChannelSuccess(channel));
    } catch (err) {
      dispatch(actions.firstChannelFail(err));
    }
  };
};

export const deleteStreamsAsync = (
  streams: StreamListItem[]
): ThunkTypeAction<void> => {
  return async (dispatch: ThunkTypeDispatch) => {
    dispatch(actions.deleteStreams(streams.map((e) => e.id)));

    try {
      await service.deleteStreams(streams);

      if (streams.length === 1) {
        dispatch(
          pushNotification(
            i18n.t("streams.deletedSingle", { streamName: streams[0].name }),
            "success"
          )
        );
      } else {
        dispatch(
          pushNotification(
            i18n.t("streams.deletedMultiple", { streamCount: streams.length }),
            "success"
          )
        );
      }
      dispatch(actions.deleteStreamsSuccess(streams));
    } catch (err) {
      dispatch(actions.deleteStreamsFail(err));
      dispatch(pushNotification(err.message, "error"));
    }
  };
};

export const saveStreamAsync = (
  bladeId: string,
  stream: StreamFormModel
): ThunkTypeAction<void> => {
  return async (dispatch: ThunkTypeDispatch, getState: () => AppState) => {
    const isCreate = stream.id == 0;

    dispatch(actions.saveStreamStart(stream));
    try {
      const fn = isCreate ? service.saveStream : service.updateStream;

      const savedStream = await fn(stream);

      const hardware = selectDeviceById(stream.hardwareId)(getState());
      savedStream.hardwareSerialNumber = savedStream.hardwareSerialNumber
        ? savedStream.hardwareSerialNumber
        : hardware?.serial || "";

      batch(() => {
        dispatch(actions.saveStreamSuccess(savedStream, isCreate));
        dispatch(
          actions.setPreselected([{ id: savedStream.id } as StreamListItem])
        );
        dispatch(
          updateBladeProps(bladeId, StreamFormBladeName, {
            streamName: savedStream.name,
            streamId: savedStream.id,
          })
        );
      });
    } catch (err) {
      dispatch(catchSaveError(err));
    }
  };
};

export const catchSaveError = (
  error: ConcurrencyError | ErrorResponse
): ThunkTypeAction<void> => {
  return (dispatch: ThunkTypeDispatch) => {
    if (error instanceof ConcurrencyError) {
      dispatch(actions.saveStreamFail(error));
      dispatch(pushNotification(i18n.t("streamForm.concurrency"), "error"));
      return;
    }

    if (error instanceof BadRequestError) {
      dispatch(actions.saveStreamFail(error));
      dispatch(pushNotification(i18n.t("streamForm.serialConflict"), "error"));
      return;
    }

    dispatch(actions.saveStreamFail(error));
    dispatch(pushNotification(error.message, "error"));
  };
};

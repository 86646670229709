import { FlexFadeIn } from "components";
import { MathProblemConfirmDialog } from "react-tools";
import { WorkgroupFilterFooter } from "components/ExternalFilterFooter";
import { WorkgroupLocation } from "models";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DataGrid,
  DataGridHeader,
  DataGridSelectionType,
  Footer,
  GridCard,
  IdName,
  useBladeButtons,
  useBladeClosing,
  useGridApi,
} from "react-tools";

import { useLocationListBladeButtons } from "./buttons.hook";
import { locationsColumnSettings } from "./column.settings";
import { LocationListLoader } from "./loader";
import { useStyles } from "./locations.jss";
import { LocationService } from "location/duck/service";

export interface WorkgroupLocationsProps {
  items: Array<WorkgroupLocation>;
  workgroup: IdName;
  preselected: WorkgroupLocation[];
  fetchingLocations: boolean;
  bladeId: string;
}

export interface WorkgroupLocationsActions {
  onClose: () => void;
  fetchLocations: (workgroupId: number) => void;
  createLocation: () => void;
  editLocation: (workgroup: WorkgroupLocation) => void;
  deleteLocations: (workgroups: WorkgroupLocation[]) => void;
  clearPreselected: () => void;
}

type Props = WorkgroupLocationsProps & WorkgroupLocationsActions;

const service = new LocationService();

export const WorkgroupLocations: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  const [columns] = useState(locationsColumnSettings);
  const gridApi = useGridApi();
  const [t] = useTranslation();

  useBladeClosing(
    props.bladeId,
    () => true,
    () => {
      props.onClose();
    }
  );
  const [selected, setSelected] = useState<WorkgroupLocation[]>([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deviceCount, setDeviceCount] = useState(0);

  useEffect(() => {
    if (props.workgroup.id) {
      props.fetchLocations(props.workgroup.id);
    }
  }, [props.workgroup.id]);

  useEffect(() => {
    setSelected(props.preselected || []);
  }, [props.preselected]);

  const fetchLocationsCallback = useCallback(() => {
    props.fetchLocations(props.workgroup.id);
    props.clearPreselected();
    setSelected([]);
  }, [props.fetchLocations, props.workgroup.id]);

  const recurseFilterFn = useCallback(
    (row: any) => {
      return row.data.workgroupId === props.workgroup.id;
    },
    [props.workgroup.id]
  );

  const confirmDelete = useCallback(() => {
    service
      .countHardwaresForLocations(selected)
      .then(({ count }: { count: number }) => {
        setDeleteDialogOpen(true);
        setDeviceCount(count);
      });
  }, [setDeleteDialogOpen, selected]);

  const deleteCallback = useCallback(() => {
    props.deleteLocations(selected);
    setDeleteDialogOpen(false);
    setSelected([]);
  }, [selected, props.deleteLocations]);

  const closeDeleteConfirmationCallback = useCallback(() => {
    setDeleteDialogOpen(false);
  }, [setDeleteDialogOpen]);

  const buttons = useLocationListBladeButtons(
    fetchLocationsCallback,
    props.createLocation,
    confirmDelete,
    selected,
    props.fetchingLocations,
    gridApi
  );

  useBladeButtons(buttons, [buttons]);

  const onWorkgroupLocations = useCallback(
    (items: IdName[]) => setSelected(items as WorkgroupLocation[]),
    [props.editLocation]
  );

  const onRowSelect = useCallback(
    (item: WorkgroupLocation) => {
      setSelected([item]);
      props.editLocation(item);
    },
    [gridApi, props.editLocation]
  );

  const confirmMessage = useMemo(() => {
    if (!selected) {
      return "";
    }

    return selected.length > 1
      ? t<string>("locations.deleteMultiple", {
          count: selected.length,
        })
      : t<string>("locations.deleteSingle", {
          name: selected[0] && selected[0].name,
          interpolation: { escapeValue: true }
        });
  }, [selected, t]);

  return props.fetchingLocations ? (
    <LocationListLoader />
  ) : (
    <FlexFadeIn>
      <div className={classes.container}>
        <GridCard>
          <DataGridHeader loading={props.fetchingLocations} />
          <DataGrid
            onRowClicked={onRowSelect}
            onSelect={onWorkgroupLocations}
            identifier={"workgroupLocations"}
            noDataMessage={"workgroupLocations.noResults"}
            selectionType={DataGridSelectionType.Multiple}
            defaultSelection={props.preselected}
            items={props.items}
            columnSettings={columns}
            externalFilterFn={recurseFilterFn}
            isExternalFilterEnabled={() => true}
          />
        </GridCard>
        <MathProblemConfirmDialog
          title={t<string>("locations.deleteTitle")}
          open={deleteDialogOpen}
          onSave={deleteCallback}
          message={confirmMessage}
          countCheck={deviceCount}
          countCheckLabelKey={"locations.deleteCountCheckHelperText"}
          extraMessage={t("locations.deleteDialogExtraText", { deviceCount })}
          warning={t<string>("locations.deleteWarning")}
          checkboxText={t<string>("locations.checkboxText")}
          onCancel={closeDeleteConfirmationCallback}
        />
        <Footer>
          <WorkgroupFilterFooter />
        </Footer>
      </div>
    </FlexFadeIn>
  );
};

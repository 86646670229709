import { format } from 'date-fns';
import {
  SettingsEditor,
  DeviceSettingModel,
  DeviceSettingType,
  EntityType,
} from 'react-tools';
import i18next from 'i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ICellRendererParams } from '@ag-grid-enterprise/all-modules';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from '@material-ui/core';

import { withStyles } from '@material-ui/styles';

const cellPStyle: React.CSSProperties = {
  marginBottom: 'auto',
  marginTop: 'auto',
  paddingLeft: '8px',
  paddingRight: '4px',
};

const groupStyle: React.CSSProperties = {
  fontWeight: 600,
};

const criticalStyle: React.CSSProperties = {
  color: 'red',
  fontWeight: 'bold',
  paddingLeft: '8px',
  paddingRight: '4px',
  marginBottom: 'auto',
  marginTop: 'auto',
};

export class SettingColumnRenderer extends React.Component<
  ICellRendererParams,
  any
> {
  constructor(props: ICellRendererParams) {
    super(props);
  }

  render() {
    return this.props.node.group ? (
      <span style={groupStyle}>{this.props.value}</span>
    ) : this.props.node.data.riskLevel === 0 ? (
      <Tooltip title={i18next.t('deviceSettings.criticalSetting').toString()}>
        <p style={criticalStyle}>{this.props.value}</p>
      </Tooltip>
    ) : (
      <p style={cellPStyle}>{this.props.value}</p>
    );
  }
}

const StyledButton = withStyles({
  root: {
    marginBottom: 'auto',
    marginTop: 'auto',
    paddingLeft: '8px',
    paddingRight: '4px',
  },
})(Button);

const StyledSettingsEditor = withStyles({
  form: {
    height: 'initial',
  },
})(SettingsEditor);

export const ValueColumnFormatter = (item: ICellRendererParams) => {
  const [t] = useTranslation();
  const [open, setOpen] = useState(false);
  const row: DeviceSettingModel = item.data;
  const [showChild, setShowChild] = useState(false);

  const closeDialog = useCallback(() => setOpen(false), []);
  const openDialog = useCallback(() => setOpen(true), []);

  const dialogTitle = useMemo(
    () =>
      row
        ? t('deviceSettings.valueDialogTitle', { key: t(row.key) }).toString()
        : '',
    [row]
  );

  // Wait until after client-side hydration to show
  useEffect(() => {
    setShowChild(true);
  }, []);

  if (!showChild) {
    // You can show some kind of placeholder UI here
    return <span></span>;
  }

  if (row && row.type === DeviceSettingType.jsonb && row.value) {
    return (
      <>
        <Tooltip title={dialogTitle}>
          <StyledButton style={cellPStyle} onClick={openDialog}>
            ...
          </StyledButton>
        </Tooltip>
        <Dialog open={open} onClose={closeDialog}>
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogContent>
            <StyledSettingsEditor
              parentSettings={null}
              onSubmit={() => {}}
              readOnly={true}
              settings={[row]}
              entity={{ entityId: 1, entityType: EntityType.Workgroup }} // fake value, doesn't really matter since this is readOnly
              parentEntity={null}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog} color='primary'>
              {t('close')}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  return (
    <div style={cellPStyle}>
      {item.value !== undefined ? item.value + '' : ''}
    </div>
  );
};

export const TimestampColumnFormatter = (item: ICellRendererParams) => {
  const date = new Date(item.value);
  if (item.value && !isNaN(date.getTime())) {
    return <div style={cellPStyle}>{format(date, 'd MMM yyyy H:mm')}</div>;
  }
  return <span></span>;
};

export const InheritedColumnFormatter = (item: ICellRendererParams) => {
  return (
    <div style={cellPStyle}>
      {item.node.group ? '' : i18next.t(item.value ? 'yes' : 'no')}
    </div>
  );
};

import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-material.css';

import { AppLayout } from 'layout/layout';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { BladeRowContainer, NotifierContainer } from 'react-tools';
import { bladeTheme, moodTheme } from 'themes.jss';

import { createGenerateClassName } from '@material-ui/core';
import { StylesProvider } from '@material-ui/styles';
import ThemeProvider from '@material-ui/styles/ThemeProvider';

const generateClassName = createGenerateClassName({
  productionPrefix: 'mvaa',
  disableGlobal: true,
});

export const App: React.FC<{}> = () => (
  <StylesProvider generateClassName={generateClassName}>
    <ThemeProvider theme={moodTheme}>
      <SnackbarProvider maxSnack={3}>
        <AppLayout>
          <NotifierContainer />
          <BladeRowContainer theme={bladeTheme} />
        </AppLayout>
      </SnackbarProvider>
    </ThemeProvider>
  </StylesProvider>
);

export default App;

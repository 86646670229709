import {
  deviceSettingsActions,
  deviceSettingsSelectors,
  Entity,
  useDefaultDeviceSettingFns,
} from 'react-tools';
import i18n from 'localization/i18n';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BladeConfig,
  BladeProps,
  DataGridContextProvider,
  IdName,
  withRegisterBlade,
} from 'react-tools';

import { DeviceSettings } from './device-settings';

export const DeviceSettingsBladeName = 'DEVICE_SETTINGS';

export interface DeviceSettingsContainerProps {
  workgroup?: IdName;
  location?: IdName;
  device?: IdName;
  parentEntity: Entity | null;
}

const bladeConfig: BladeConfig<DeviceSettingsContainerProps> = {
  bladeType: DeviceSettingsBladeName,
  size: { defaultWidth: 800, minWidth: 400 },
  title: () => i18n.t('deviceSettings.title'),
  allowMultipleInstances: false,
};

const ContainerComponent = ({
  workgroup,
  location,
  device,
  bladeId,
  parentEntity,
}: DeviceSettingsContainerProps & BladeProps) => {
  const dispatch = useDispatch();

  const deviceSettings = useSelector(
    deviceSettingsSelectors.selectEntitySettings
  );
  const isFetching = useSelector(
    deviceSettingsSelectors.selectIsFetchingDeviceSettings
  );

  const clearDeviceSettings = useCallback(() => {
    dispatch(deviceSettingsActions.clearDeviceSettings());
  }, []);

  const {
    fetchDevicesSettings,
    openDeviceSettings,
  } = useDefaultDeviceSettingFns(bladeId, parentEntity);

  return (
    <DataGridContextProvider>
      <DeviceSettings
        clearDeviceSettings={clearDeviceSettings}
        workgroup={workgroup}
        location={location}
        device={device}
        bladeId={bladeId}
        fetchDevicesSettings={fetchDevicesSettings}
        openWizard={openDeviceSettings}
        deviceSettings={deviceSettings}
        isFetching={isFetching}
        parentEntity={parentEntity}
      />
    </DataGridContextProvider>
  );
};

export const DeviceSettingsContainer = withRegisterBlade<
  DeviceSettingsContainerProps & BladeProps
>(bladeConfig)(ContainerComponent);

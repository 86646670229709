import i18n from "localization/i18n";
import { StreamListItem } from "models/streams";
import React, { useCallback } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import {
  BladeActions,
  BladeConfig,
  BladeProps,
  DataGridContextProvider,
  IdName,
  useBladeClosing,
  withRegisterBlade,
} from "react-tools";
import { actions, selectors, thunks } from "stream/duck";

import { Streams } from "./streams";

export const StreamsBladeName = "STREAMS";

interface StreamsContainerProps extends BladeProps {
  workgroup: IdName;
  channel?: IdName;
  device?: IdName;
}

const bladeConfig: BladeConfig<StreamsContainerProps> = {
  bladeType: StreamsBladeName,
  size: { defaultWidth: 800, minWidth: 400 },
  title: () => i18n.t("streams.title"),
  allowMultipleInstances: false,
};

const ContainerComponent = ({
  workgroup,
  channel,
  device,
  bladeId,
}: StreamsContainerProps & BladeProps) => {
  const dispatch = useDispatch();

  const items = useSelector(selectors.selectStreams);
  const fetchingStreams = useSelector(selectors.selectIsFetchingStreams);
  const preselected = useSelector(selectors.selectPreselected);

  const fetchStreams = useCallback(
    (channel, device) => dispatch(thunks.fetchStreamsAsync(channel, device)),
    [channel, device]
  );

  const deleteStreams = useCallback(
    (streams: StreamListItem[]) =>
      batch(() => {
        dispatch(BladeActions.forceCloseChildrenBlades(bladeId));
        dispatch(thunks.deleteStreamsAsync(streams));
      }),
    [bladeId]
  );

  const createStream = useCallback(() => {
    if (device) {
      batch(() => {
        dispatch(
          BladeActions.closeChildrenBlades(bladeId, [
            actions.clearStreamDetails(),
            actions.openStreamForm(bladeId, {
              workgroup,
              streamName: "",
              streamId: 0,
              device,
            }),
          ])
        );
      });
    }
  }, [channel?.id, device?.id]);

  const editStream = useCallback(
    (stream: StreamListItem) => {
      if (device) {
        batch(() => {
          dispatch(actions.setPreselected([stream]));
          dispatch(
            BladeActions.closeChildrenBlades(
              bladeId,
              actions.openStreamForm(bladeId, {
                workgroup,
                streamName: stream.name,
                streamId: stream.id,
                device,
              })
            )
          );
        });
      }
    },
    [channel?.id, device?.id]
  );

  const clearPreselected = useCallback(
    () => dispatch(actions.setPreselected([])),
    [dispatch]
  );

  useBladeClosing(
    bladeId,
    () => true,
    () => {
      dispatch(actions.clearStreamDetails());
      dispatch(actions.setPreselected([]));
    }
  );

  return (
    <DataGridContextProvider>
      <Streams
        bladeId={bladeId}
        clearPreselected={clearPreselected}
        preselected={preselected}
        channel={channel}
        device={device}
        items={items}
        fetchStreams={fetchStreams}
        fetchingStreams={fetchingStreams}
        createStream={createStream}
        editStream={editStream}
        deleteStreams={deleteStreams}
      />
    </DataGridContextProvider>
  );
};

export const StreamsContainer =
  withRegisterBlade(bladeConfig)(ContainerComponent);

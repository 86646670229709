import { DeviceFormContainerProps } from 'device/form/device-form.container';
import { DeviceFormModel, DeviceListItem } from 'models/devices';
import { openBlade, ErrorResponse } from 'react-tools';
import { action } from 'typesafe-actions';

import { DeviceFormBladeName } from '../form/device-form.container';
import { ActionTypes } from './types';

export const fetchDevicesStart = (workgroupId?: number, locationId?: number) =>
  action(ActionTypes.FETCH_DEVICES_START, { workgroupId, locationId });
export const fetchDevicesSuccess = (devices: DeviceListItem[]) =>
  action(ActionTypes.FETCH_DEVICES_SUCCESS, { devices });
export const fetchDevicesFail = (error: any) =>
  action(ActionTypes.FETCH_DEVICES_FAIL, { error });

export const fetchDeviceStart = () => action(ActionTypes.FETCH_DEVICE_START);
export const fetchDeviceSuccess = (device: DeviceFormModel) =>
  action(ActionTypes.FETCH_DEVICE_SUCCESS, { device });
export const fetchDeviceFail = (error: any) =>
  action(ActionTypes.FETCH_DEVICE_FAIL, { error });

export const saveDeviceStart = (device: DeviceFormModel) =>
  action(ActionTypes.SAVE_DEVICE_START, { device });
export const saveDeviceSuccess = (
  device: DeviceFormModel,
  create: boolean,
  sameWorkgroup: boolean
) => action(ActionTypes.SAVE_DEVICE_SUCCESS, { device, create, sameWorkgroup });
export const saveDeviceFail = ({ message, status }: ErrorResponse) =>
  action(ActionTypes.SAVE_DEVICE_FAIL, { message, status });

export const clearDeviceDetails = () =>
  action(ActionTypes.CLEAR_DEVICE_DETAILS);
export const setPreselected = (devices: DeviceListItem[]) =>
  action(ActionTypes.SET_PRESELECTED, { devices });

export const openDeviceForm = (
  parentBladeId: string,
  props: DeviceFormContainerProps
) =>
  openBlade<DeviceFormContainerProps>(parentBladeId, DeviceFormBladeName, {
    ...props,
  });

export const deleteDevices = (devices: DeviceListItem[]) =>
  action(ActionTypes.DELETE_DEVICES, { devices });
export const deleteDevicesSuccess = (devices: DeviceListItem[]) =>
  action(ActionTypes.DELETE_DEVICES_SUCCESS, { devices });
export const deleteDevicesFail = (devices: DeviceListItem[]) =>
  action(ActionTypes.DELETE_DEVICES_FAIL, { devices });
